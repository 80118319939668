import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-segmentation-submissions',
  templateUrl: './segmentation-submissions.component.html',
  styleUrls: ['./segmentation-submissions.component.scss']
})
export class SegmentationSubmissionsComponent implements OnInit {

  isLoading                     = true;

  members                       = [];
  responseData                  = [];
  clientSlug: any;
  clientId: number;
  meetingId: number;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Segmentation Submissions | CornerStone Prework");
  }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.members          = data.data;

          this.api.get('v1/meetings/' + this.meetingId + '/segmentationResponses')
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
                this.responseData         = data.data;
                this.isLoading            = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
    }, err => {
      this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });

  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : null;
    }
    return initials;
  }

  responsesByMemberId(memberId) {
    return this.responseData.filter( function (obj) { return obj.member_id == memberId; });
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      return member.firstName + " " + member.lastName;
    }
  }

  setDate(date) {

    if(date) {
      let newdate;
      let time;
      newdate       = date.substring(0, 10);
      time          = date.substring(11);

      return this.formatDate(newdate) + "&nbsp;<span>" + time.substring(0,5) + "</span>";

    } else {
      return "&nbsp;<span>-</span>";
    }
  }

  formatDate(d) {

    var date = new Date(d);

    if ( isNaN( date .getTime() ) ) {
      return d;
    } else {

      var month = new Array();
      month[0] = "Jan";
      month[1] = "Feb";
      month[2] = "Mar";
      month[3] = "Apr";
      month[4] = "May";
      month[5] = "June";
      month[6] = "July";
      month[7] = "Aug";
      month[8] = "Sept";
      month[9] = "Oct";
      month[10] = "Nov";
      month[11] = "Dec";

      let day         = date.getDate();
      let dayText     = "";

      if(day < 10) {
        dayText = "0" + day;
      } else {
        dayText = day.toString();
      }

      return    month[date.getMonth()] + " " + dayText + ", " + date.getFullYear();
    }

  }

  getMemberName(id) {
    let member          = this.members.find(function (obj) { return obj.id == id; })
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

}
