import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { forkJoin } from 'rxjs';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';


@Component({
  selector: 'app-segmentation',
  templateUrl: './segmentation.component.html',
  styleUrls: ['./segmentation.component.scss']
})
export class SegmentationComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;
  isSubmitting                = false;

  clientId: number;
  clientSlug: string;
  meetingId: number;
  preworkVideo: string;

  /*segmentationData           = [
    {
      id: 1, question: 'How can you optimally categorize your audience?', subHeading: 'Please add the most relevant segments as well.',
      questions: [
        { id: 1, question: '', answer:'', responses: [] },
        { id: 2, question: '', answer:'', responses: [] },
      ]
    },
  ];*/

  categories = [
    { id: 1, question: '', answer:'', responses: [], categoryId: null },
    { id: 2, question: '', answer:'', responses: [], categoryId: null },
  ]

  @ViewChild('responsesInput', {static: false}) responsesInput: ElementRef<HTMLInputElement>;
  @ViewChild('responsesChipList', {static: false}) responsesChipList;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private titleService:Title ) {
      this.titleService.setTitle("Segmentation | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    let meetingapi              = this.api.get('v1/meetings/slug/' + this.clientSlug);
    let accountapi              = this.api.get('v2/accounts/clients/' + this.auth.getMeetingId() + '/settings');

    forkJoin([ meetingapi, accountapi ])
      .subscribe(results => {
        console.log(results);
        if(results[0].status == "success" && results[1].status == "success") {

          this.clientId         = results[0].clientId;
          this.meetingId        = results[0].meetingId;

          let video             = results[1].data.find( function (obj) { return obj.setting == "segmentation_prework_video";});
          if(video) {
            this.preworkVideo   = video.video;
          }

          this.api.get('v1/meetings/' + this.meetingId + '/segmentationResponses/byUser/' + this.auth.getUserId())
          .subscribe(data => {
            console.log(data);
            if(data.status == "success") {
              let responses         = data.data.sort((a, b) => a.orderno - b.orderno);
              let totalResponses    = responses.length;
              responses.forEach((response, index) => {
                this.categories[index].question   = response.category;
                this.categories[index].categoryId = response.id;
                this.categories[index].responses  = response.segments.map(segment => segment.segment);
                if(totalResponses - 2 > index) {
                  this.addAnother();
                }
              });
              this.isLoading        = false;
            } else {
              this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
            }
          }, err => {
            this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  submitData() {

    if(!this.isSubmitting) {

      this.isSubmitting           = true;

        let categories    = this.categories.filter(category => category.question).length;
        let responses     = this.categories.filter(category => category.responses.length > 0).length;

        if(responses > categories) {
          this.ui.showSnackbar("Please add the category to submit.", "failure");
          return;
        }

        const payload = {
          memberId: this.auth.getUserId(),
          memberName: this.auth.getUserName(),
          categories: this.categories.filter(category => category.question)
        }
        console.log(payload);

        this.api.post('v1/meetings/' + this.meetingId + '/addMultipleCategories', payload)
          .subscribe(data => {
            if(data.status == "success") {
              this.showThankYou     = true;
              this.isSubmitting     = false;
            } else {
              this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
            }
          }, err => {
            this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

      }

  }

  showForm() {
    console.log(this.categories);
    this.categories.forEach(cat => {
      cat.answer      = "";
    });
    // reset
    // this.categories = this.categories.map(question => ({...question, answer:'', responses: [] }));
    // hide thank you page
    this.showThankYou                  = false;
  }

  removeResponse(response: string,  questionId): void {
    let question      = this.categories.find( question => question.id === questionId );
    if(question) {
      const index = question.responses.indexOf(response);
      if (index >= 0) {
        question.responses.splice(index, 1);
      }
    }
  }

  addResponse(event: any, questionId): void {

    const input = event.input;
    const value = event.value;

    // let question     = this.segmentationData[0].questions.find( question => question.id === questionId );
    let question     = this.categories.find( question => question.id === questionId );
    if(question) {
      if ((value || '').trim()) {
        question.responses.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  addAnother() {
    this.categories.push(
      {  id: this.categories.length + 1, question: '', answer:'', responses: [], categoryId: null }
    );
    /*this.segmentationData[0].questions.push(
      {  id: this.segmentationData[0].questions.length + 1, question: '', answer:'', responses: [] }
    );*/
  }

  checkDisabled() {
    if(this.isSubmitting) {
      return "csbutton next-button yellowbtn inactive";
    }
    return "csbutton next-button yellowbtn";
  }
  
}
