<div class="cs-tabs bold-version brand-p-tabs" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          Imagine your brand as a person you just met at a party.<br>
          What kind of personality does this person have? How do you perceive him/her?
        </div>
        <div class="video-frame">
          <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
            <source src="https://cornerstone.40parables.com/wp-content/uploads/2021/05/Brand-Personality.mp4"
              type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame">

        <div class="question-instructions">
          Please answer with single words or short phrases.
        </div>

        <div class="question-content" *ngIf="!showThankYou">

          <div class="brand-personality-wrapper">

            <div class="brand-personality-numbers">
              {{ currentIndex }} / {{ maxIndex + 1 }}
            </div>
                        
            <div class="arrows-wrapper">
              <div (click)="moveStepperBack(currentIndex, stepper)" [ngClass]="checkLeftArrowActive(currentIndex)">
                <span class="material-icons">chevron_left</span>
              </div>
              <div (click)="moveStepper(currentIndex + 1, stepper)" [ngClass]="checkRightArrowActive(currentIndex)" style="margin-left: 2px;">
                <span class="material-icons">chevron_right</span>
              </div>
            </div>

            <mat-horizontal-stepper #stepper linear>

              <mat-step *ngFor="let question of questions">
                <div class="question-block">
                  <div class="question-block-title">{{ question.question }}</div>
                  <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput [placeholder]="question.placeholder" [(ngModel)]="question.answer" #responsesInput
                      [matChipInputFor]="responsesChipList" [matChipInputAddOnBlur]="true"
                      (matChipInputTokenEnd)="addResponse($event, question.id)">
                  </mat-form-field>
                  <mat-chip-list #responsesChipList class="prework-chip">
                    <mat-chip *ngFor="let response of question.responses; let i = index" [selectable]="true"
                      [removable]="true" (removed)="removeResponse(response, question.id)">
                      {{response}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </mat-step>

            </mat-horizontal-stepper>
            <div class="actionBtn">
              <button mat-button class="csbutton next-button"
                (click)="currentIndex < maxIndex + 1 ? moveStepper(currentIndex + 1, stepper) : submitForm()">
                <ng-container *ngIf="currentIndex < maxIndex + 1">Next</ng-container>
                <ng-container *ngIf="currentIndex == maxIndex + 1">Share your responses</ng-container>
              </button>
            </div>
          </div>

        </div>

        <div class="thank-you" *ngIf="showThankYou">
          <div class="heading"><span class="material-icons">check_circle</span>Your response have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br />
            <a (click)="showForm()">Click here</a> to refresh and add more responses.
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fa fa-star"></i>
        Examples
      </ng-template>
      <div class="example-content">
        <table>
          <tr>
            <th></th>
            <th>COCA-COLA</th>
            <th>RED BULL</th>
            <th>NIKE</th>
            <th>HARLEY DAVIDSON</th>
          </tr>
          <tr>
            <td>Brand Identity</td>
            <td>Radiant</td>
            <td>Edgy</td>
            <td>Efficient</td>
            <td>Rugged</td>
          </tr>
          <tr>
            <td>Language</td>
            <td>Joyful</td>
            <td>Energetic</td>
            <td>Champion</td>
            <td>Gritty</td>
          </tr>
          <tr>
            <td>Brand Voice</td>
            <td>Enthusiastic</td>
            <td>Caffeinated</td>
            <td>Motivational</td>
            <td>Rough</td>
          </tr>
          <tr>
            <td>Characteristics</td>
            <td>Optimistic</td>
            <td>Extreme</td>
            <td>Brave</td>
            <td>Rebellious</td>
          </tr>
          <tr>
            <td>Motivation</td>
            <td>Enjoyment</td>
            <td>Adrenaline</td>
            <td>Winning</td>
            <td>Liberation</td>
          </tr>
          <tr>
            <td>Fear</td>
            <td>Unhappiness</td>
            <td>Losing</td>
            <td>Calm</td>
            <td>Conformity</td>
          </tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>