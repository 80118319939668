import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-brand-messaging',
  templateUrl: './brand-messaging.component.html',
  styleUrls: ['./brand-messaging.component.scss']
})
export class BrandMessagingComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;
  isSubmitting                = false;

  clientSlug: string;
  clientId: number;
  meetingId: number;

  brandMessagingData           = [
    {
      id: 1,
      tab: 'PROBLEM',
      question: 'What problem is your brand solving?',
      label:'Be specific. Make sure it’s a pain point. Get it down to a sound bite.',
      placeholder: 'E.g. lack of team alignment on vision and strategy',
      answer: null
    },
    {
      id: 2,
      tab: 'PLAN',
      question: 'What is your plan to help your target audience?',
      label:'Make it feel like a new idea. Make it understandable. Keep it brief.',
      placeholder: 'E.g. practical exercises to get the team on the same page',
      answer: null
    },
    {
      id: 3,
      tab: 'PROMISE',
      question: 'Describe a successful ending to their story.',
      label:'Make it the main idea of your business. Make it something they want.',
      placeholder: 'E.g. harmonious alignment from top to bottom',
      answer: null
    },
  ];

  @ViewChild('stepper', { static: false }) stepper : MatStepper;
  currentIndex            = 1;
  maxIndex                = this.brandMessagingData.length;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private titleService: Title ) {
      this.titleService.setTitle("Brand Messaging | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;

          this.isLoading        = false;

          this.api.get('v1/meetings/' + this.meetingId + '/brandingResponse/byUser/' + this.auth.getUserId())
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {

                this.brandMessagingData[0].answer = data?.data?.problem;
                this.brandMessagingData[1].answer = data?.data?.solution;
                this.brandMessagingData[2].answer = data?.data?.outcome;

                this.isLoading    = false;

              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  submitData() {

    if(!this.isSubmitting) {

      this.isSubmitting           = true;

      const payload = {
        memberId: this.auth.getUserId(),
        memberName: this.auth.getUserName(),
        problem: this.brandMessagingData[0].answer,
        solution: this.brandMessagingData[1].answer,
        outcome: this.brandMessagingData[2].answer,
      };

      //this.isLoading              = true;

      console.log(payload);

      this.api.post('v1/meetings/' + this.meetingId + '/brandMessaging/addMultiple', payload)
        .subscribe(data => {
          if(data.status == "success") {
            // this.isLoading        = false;
            this.showThankYou     = true;
            this.isSubmitting     = false;
          } else {
            this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
          }
        }, err => {
          this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
        });

    }

  }

  setIndex(event, stepper: MatStepper) {
    if(event.selectedIndex < this.maxIndex && event.selectedIndex >= 0) {
      this.currentIndex       = event.selectedIndex + 1;
    }
  }

  moveStepper(index: number, stepper: MatStepper) {
    if(index <= this.maxIndex && index > 0) {
      stepper.selectedIndex   = index - 1;
      this.currentIndex       = index;
    }
  }

  showForm() {
    this.showThankYou           = false;
    setTimeout(() => {this.moveStepper(1, this.stepper);}, 100);
  }

  checkDisabled() {
    if(this.isSubmitting) {
      return "csbutton next-button inactive";
    }
    return "csbutton next-button";
  }

}
