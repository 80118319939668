import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { MatTabGroup } from '@angular/material/tabs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';


@Component({
  selector: 'app-brand-voice-chart',
  templateUrl: './brand-voice-chart.component.html',
  styleUrls: ['./brand-voice-chart.component.scss']
})
export class BrandVoiceChartComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;

  clientSlug: string;
  clientId: number;
  meetingId: number;

  // members                     = [];
  // filteredOptions             = [];
  // finalMember: number;
  // memberSearch: any;
  // newMember:any;
  // hideAddNewOption            = true;

  voiceChartData              = [];

  @ViewChild('MatTabGroup', { static: false }) tabGroup : MatTabGroup;
  selectedIndex               = 0;
  maxIndex                    = 0;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Brand Voice Chart | CornerStone Prework");
  }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;

          this.api.get('v1/meetings/' + this.meetingId + '/voicetone/adjectives/selectedthisnotthat')
            .subscribe(data => {
              if(data.status == "success") {

                this.api.get('v1/meetings/' + this.meetingId + '/voicetone/chartsByMember/' + this.auth.getUserId())
                  .subscribe(chart => {
                    console.log(chart);
                    if(chart.status == "success") {
                
                      data.data.forEach(charts => {
                        if(charts) {
                          let answers         = [];
                          let userchart       = chart.data.find( function (obj) { return obj.id == charts.id; });
                          if(userchart) {
                            if(userchart.answers.length > 0) {
                              answers.push(userchart.answers[0].synonyms);
                              answers.push(userchart.answers[0].description);
                              answers.push(userchart.answers[0].how_to_use);
                              answers.push(userchart.answers[0].how_not_to_use);
                            }
                          }
                          this.voiceChartData.push({ id: charts.id, tab: charts.adjective, answers: answers });
                        }
                      });
                      
                      console.log(this.voiceChartData);
                      this.maxIndex         = this.voiceChartData.length - 1;
                      this.isLoading        = false;

                    } else {
                      this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
                    }
                  }, err => {
                    this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
                  });
                
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  // filterMember(val) {
  //   if (val) {
  //     const filterValue       = val.toString().toLowerCase();
  //     console.log(val);
  //     if(this.finalMember === 99999999) {
  //       this.hideAddNewOption = true;
  //       this.finalMember      = null;
  //     }

  //     if(val && this.members.find(member => member.name.toLowerCase() == val.toLowerCase())) {
  //       this.hideAddNewOption = true;
  //     } else {
  //       this.hideAddNewOption = false;
  //     }
  //     return this.members.filter(member => member.name.toLowerCase().startsWith(filterValue));
  //   } else {
  //     this.hideAddNewOption   = true;
  //   }
  //   return this.members;
  // }

  // get getNewMember() {
  //   let value =  this.memberSearch;
  //   if(typeof(value) === 'string') {
  //      this.newMember = value;
  //   }
  //   if(typeof(value) === 'number') {
  //     return this.displayFn(value);
  //   }
  //   return value;
  // }

  // displayFn(mem) {
  //   if (!mem) return '';
  //   if(mem === 99999999) {
  //     this.finalMember        = mem;
  //     return this.memberSearch;
  //   }
  //   let index = this.members.findIndex(member => member.id === mem.id);
  //    if(index != -1) {
  //     this.finalMember        = this.members[index].id;
  //    } else {
  //     this.finalMember        = null;
  //    }
  //   return this.members[index].name;
  // }

  // addMember() {
  //   let member    = this.newMember.split(" ");
  //   const payload = {
  //     firstName : member[0],
  //     lastName : member[1] ? member[1] : "",
  //   };

  //   this.api.post('v1/meetings/' + this.meetingId + '/member/' + this.clientId + '/add', payload)
  //     .subscribe(data => {
  //       console.log(data);
  //       if(data.status == "success") {
  //         this.finalMember = data.data.insertId;
  //         this.hideAddNewOption = true;
  //         this.ngOnInit();
  //       } else {
  //         this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
  //       }
  //     }, err => {
  //         this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
  //   });

  // }

  submitData() {

    const payload = {
      memberId: this.auth.getUserId(),
      charts: this.voiceChartData,
      memberName: this.auth.getUserName()
    }

    this.api.post('v1/meetings/' + this.meetingId + '/voicechart/addMultiple', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          this.showThankYou    = true;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
      });
    // this.showThankYou    = true;
    // if(!this.finalMember) {
    //   this.ui.showSnackbar("Please enter your name", "failure");
    //   return;
    // }

    // const payload = {
    //   memberId : this.finalMember,
    // };
    // console.log(payload);

    // this.api.post('v1/preworks/client/' + this.clientId + '/brandvalues', payload)
    //   .subscribe(data => {
    //     console.log(data);
    //     if(data.status == "success") {
    //         this.showThankYou    = true;
    //     } else {
    //       this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
    //     }
    //   }, err => {
    //       this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
    // });

  }

  showForm() {
    // reset
    // this.finalMember            = null;
    // this.memberSearch           = null;
    this.selectedIndex          = 0;
    this.voiceChartData.map(data => ({ ...data, answers:[] }));
    // hide thank you page
    this.showThankYou           = false;

  }

  onTabChanged(event) {
    this.selectedIndex          = event.index;
  }

  changeTab(index) {
    this.selectedIndex          = index;
  }

}
