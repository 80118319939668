<div class="cs-tabs bold-version" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="video-content">
        Identify and clarify your brand’s core values to help you determine <br>
        the course of action that best reflects your brand’s ethos.
      </div>
      <div class="video-frame">
        <!-- <iframe width="677" src="https://cornerstone.40parables.com/wp-content/uploads/2021/04/Golden-Pyramid.mp4" frameborder="0" allowfullscreen></iframe> -->
        <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
          <source src="https://cornerstone.40parables.com/wp-content/uploads/2021/05/Brand-Values.mp4" type="video/mp4">
        </video>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame">
        <div class="question-heading">
          Please answer these questions with as much detail as possible.
        </div>
        <div class="question-content" *ngIf="!showThankYou">

          <div class="brand-values-wrapper">

            <div class="brand-values-numbers">
              {{ currentIndex }} / {{ maxIndex + 1 }}
            </div>

            <div class="arrows-wrapper">
              <div (click)="moveStepperBack(currentIndex, stepper)" [ngClass]="checkLeftArrowActive(currentIndex)"><span
                  class="material-icons">chevron_left</span></div>
              <div (click)="saveBrandValue(currentIndex)" [ngClass]="checkRightArrowActive(currentIndex)"
                style="margin-left: 2px;"><span class="material-icons">chevron_right</span></div>
            </div>

            <mat-horizontal-stepper #stepper linear>

              <mat-step *ngFor="let question of questions">
                <div class="question-block">
                  <div class="question-block-title">{{ question.question }}</div>
                  <mat-form-field appearance="outline" floatLabel="never">
                    <input matInput [placeholder]="question.placeholder" [(ngModel)]="question.answer" #responsesInput
                      [matChipInputFor]="responsesChipList" [matChipInputAddOnBlur]="true"
                      (matChipInputTokenEnd)="addResponse($event, question.id)">
                  </mat-form-field>
                  <mat-chip-list #responsesChipList class="prework-chip">
                    <mat-chip *ngFor="let response of question.responses; let i = index" [selectable]="true"
                      [removable]="true" (removed)="removeResponse(response, question.id)">
                      {{response}}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </mat-step>

            </mat-horizontal-stepper>
            <div class="actionBtn">
              <button mat-button class="csbutton next-button" (click)="saveBrandValue(currentIndex)">
                <ng-container *ngIf="currentIndex < maxIndex + 1">Next</ng-container>
                <ng-container *ngIf="currentIndex == maxIndex + 1">Share your responses</ng-container>
              </button>

            </div>
          </div>

        </div>
        <div class="thank-you" *ngIf="showThankYou">
          <div class="heading"><span class="material-icons">check_circle</span>Your response have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br />
            <a (click)="showForm()">Click here</a> to refresh and add more responses.
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>