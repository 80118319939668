import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../shared/api.service';
import { UiService } from '../shared/ui.service';
import { AuthService } from '../shared/auth.service';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading                         = false;
  signin                            = "signin";
  signinForm: FormGroup;
  meetingId: any;
  clientId: any;
  meetingSlug: any;
  lightLogo: any;
  loginUrl: any;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  filteredOptions               = [];
  memberSearch: any;
  finalMember;
  members                       = [];
  newMember:any;
  hideAddNewOption              = true;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    private ui: UiService,
    private route: ActivatedRoute,
    private router: Router ) {
      this.route.queryParams.subscribe(params => {
        this.loginUrl = params['url'];
      });
    }

  ngOnInit() {
    this.signinForm = this.fb.group({
      meetingCode: ['', [Validators.required]],
    });
  }

  signIn() {

    console.log(this.loginUrl);

    this.signin                         = "loading";

    const payload = {
      meetingCode: this.signinForm.get('meetingCode').value
    }

    this.api.post('v1/meetings/login', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.signin                   = "login";
          this.meetingId                = data.meetingId;
          this.clientId                 = data.clientId;
          this.meetingSlug              = data.meetingSlug;

          this.members          = data.data.map(member => ({
              id: member.id,
              name: member.first_name + (member.last_name ? ' ' + member.last_name : '')
            }));
          this.filteredOptions  = this.members;

        } else if(data.code == 2002) {
          this.ui.showSnackbar("This code doesn't exist. Please retry with a valid code.", "failure");
          this.signin                   = "signin";
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });
  }

  submitLogin() {
    this.signin                         = "loading";

    const payload = {
      meetingId: this.meetingId,
      member: this.finalMember
    }

    this.api.post('v1/meetings/members/login', payload)
      .subscribe(data => {
        if(data.status == "success") {

          console.log(data);

          this.auth.setToken(data.token);
          this.auth.setUserId(data.userId);
          this.auth.setUserScope(data.userScope);
          this.auth.setUserName(data.userName);
          this.auth.setUserMeetingId(data.meetingId);
          this.auth.setUserMeetingDarkLogo(data.meetingDarkLogo);
          this.auth.setUserMeetingTitle(data.meetingTitle);
          if(this.loginUrl) {
            this.router.navigate([this.loginUrl]);
          } else {
            this.router.navigate([this.meetingSlug + '/start']);
          }
          

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });
  }

  filterMember(val) {
    if (val) {
      const filterValue       = val.toString().toLowerCase();
      console.log(val);
      if(this.finalMember === 99999999) {
        this.hideAddNewOption = true;
        this.finalMember      = null;
      }

      if(val && this.members.find(member => member.name.toLowerCase() == val.toString().toLowerCase())) {
        this.hideAddNewOption = true;
      } else {
        this.hideAddNewOption = false;
      }
      return this.members.filter(member => member.name.toLowerCase().startsWith(filterValue));
    } else {
      this.hideAddNewOption   = true;
    }
    return this.members;
  }


  displayFn(mem) {
    if (!mem) return '';
    if(mem === 99999999) {
      this.finalMember        = mem;
      return this.memberSearch;
    }
    let index = this.members.findIndex(member => member.id === mem.id);
      if(index != -1) {
      this.finalMember     =this.members[index].id;
    } else {
      this.finalMember       = null;
    }
    return this.members[index].name;
  }

  get getNewMember() {
    let value =  this.memberSearch;
    if(typeof(value) === 'string') {
        this.newMember = value;
    }
    if(typeof(value) === 'number' || typeof(value) === 'object') {
      return this.displayFn(value);
    }
    return value;
  }

  addMember() {
    let member    = this.newMember.split(" ");
    const payload = {
        firstName : member[0],
        lastName : member[1] ? member[1] : "",
    };

    this.api.post('v1/meetings/' + this.meetingId + '/member/' + this.clientId + '/add', payload)
    .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          this.finalMember = data.data.insertId;
          this.hideAddNewOption = true;
          // this.signIn();
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
    });

  }


}
