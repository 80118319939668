import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-brand-messaging-submissions',
  templateUrl: './brand-messaging-submissions.component.html',
  styleUrls: ['./brand-messaging-submissions.component.scss']
})
export class BrandMessagingSubmissionsComponent implements OnInit {

  isLoading                     = true;

  members                       = [];
  responseData                  = [];
  clientSlug: any;
  clientId: number;
  meetingId: number;

  brandMessagingData           = [
    {
      id: 1,
      tab: 'PROBLEM',
      question: 'What problem is your brand solving?',
      label:'Be specific. Make sure it’s a pain point. Get it down to a sound bite.',
      placeholder: 'E.g. lack of team alignment on vision and strategy',
      answer: null
    },
    {
      id: 2,
      tab: 'PLAN',
      question: 'What is your plan to help your target audience?',
      label:'Make it feel like a new idea. Make it understandable. Keep it brief.',
      placeholder: 'E.g. practical exercises to get the team on the same page',
      answer: null
    },
    {
      id: 3,
      tab: 'PROMISE',
      question: 'Describe a successful ending to their story.',
      label:'Make it the main idea of your business. Make it something they want.',
      placeholder: 'E.g. harmonious alignment from top to bottom',
      answer: null
    },
  ];

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Brand Messaging Submissions | CornerStone Prework");
  }

  ngOnInit(): void {
    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.members          = data.data;

          this.api.get('v1/meetings/' + this.meetingId + '/brandingMessages')
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
                this.responseData         = data.data;
                this.isLoading            = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });

  }

  showMember(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : null;
    }
    return initials;
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      return member.firstName + " " + member.lastName;
    }
  }

  responseByMemberId(memberId, tab) {
    let data =  this.responseData.filter( function (obj) { return obj.member_id == memberId; });
    if(data[0]) {
      if(tab == "PROBLEM") {
        if(data[0].problem) {
          return data[0].problem;
        }
      } else if(tab == "PLAN") {
        if(data[0].solution) {
          return data[0].solution;
        }
      } else if(tab == "PROMISE") {
        if(data[0].outcome) {
          return data[0].outcome;
        }
      }
    }
    return "";
  }


}
