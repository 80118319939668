import { Injectable } from '@angular/core';
import { Http, HttpModule, Headers, Request, RequestOptions, RequestMethod, Response } from '@angular/http';

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {

  private baseUrl = environment.apiUrl;

  constructor(
    private http: Http,
    private auth: AuthService
  ) { }


  get( url: string ) {
    return this.request(url, RequestMethod.Get);
  }

  post( url: string, body: Object ) {
    return this.request(url, RequestMethod.Post, body);
  }

  put( url: string, body: Object ) {
    return this.request(url, RequestMethod.Put, body);
  }

  delete( url: string ) {
    return this.request(url, RequestMethod.Delete);
  }
  
  request(url: string, method: RequestMethod, body?: Object) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.auth.getToken());

    const requestOptions = new RequestOptions({
      url: `${this.baseUrl}/${url}`,
      method: method,
      headers: headers
    });

    if (body) {
      requestOptions.body = body;
    }

    const request = new Request(requestOptions);

    return this.http.request(request).pipe(
      map((res: Response) => res.json()),
      catchError((res: Response) => this.onRequestError(res))
    )
      //.do(() => this.slimLoadingBarService.start())
      //.do((res: Response) => this.slimLoadingBarService.complete());
  }

  onRequestError(res: Response) {
    const statusCode = res.status;
    console.log(res);
    const body = res.json();

    const error = {
      statusCode: statusCode,
      error: body.error
    };

    console.log(error);

    return throwError(error);
  }
}
