<div class="start-page" *ngIf="!isLoading">

  <div class="frame">

    <div class="start-top">
      <div class="small-text">Welcome to</div>
      <div class="cs-text">CornerStone</div>
      <div class="small-text">Prework Exercises for</div>
      <div class="client-name">{{ meetingName }}</div>
    </div>

    <div class="start-mid">

      <div class="prework-block" *ngIf="showGoldenPyramid" (click)="goToExercise('goldenPyramid')">
        <div class="block">
          <img src="./assets/img/golden-pyramid-icon.svg">
          <h3>Golden Pyramid</h3>
        </div>
        <div class="block-hover">
          <h3>Golden Pyramid</h3>
          <div class="hover-text">
            Set a solid foundation for growth by getting clarity about who your brand is and examining its <em>raison d’etre</em>.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showProblemPriority" (click)="goToExercise('problemPriority')">
        <div class="block">
          <img src="./assets/img/problem-priority-icon.svg">
          <h3>Problem Priority</h3>
        </div>
        <div class="block-hover">
          <h3>Problem Priority</h3>
          <div class="hover-text">
            Identify your prospect’s needs and understand the problems and hurdles to help you get the right solutions.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showSegmentation" (click)="goToExercise('segmentation')">
        <div class="block">
          <img src="./assets/img/segmentation-icon.svg">
          <h3>Segmentation</h3>
        </div>
        <div class="block-hover">
          <h3>Segmentation</h3>
          <div class="hover-text">
            Identify and segment your key audiences to firmly establish your brand’s positioning in the market.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showBrandAdvocacy" (click)="goToExercise('brandAdvocacy')">
        <div class="block">
          <img src="./assets/img/brand-advocacy-icon-start.svg">
          <h3>Brand Advocacy</h3>
        </div>
        <div class="block-hover">
          <h3>Brand Advocacy</h3>
          <div class="hover-text">
            Arm your best customers with the most apt messages they can use to advocate you to their peers.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showBrandPersonality" (click)="goToExercise('brandPersonality')">
        <div class="block">
          <img src="./assets/img/brand-personality-icon-start.svg">
          <h3>Brand Personality</h3>
        </div>
        <div class="block-hover">
          <h3>Brand Personality</h3>
          <div class="hover-text">
            Humanize your brand and make it relatable to your audience by giving it a personality that’s uniquely yours.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showPersonification" (click)="goToExercise('personification')">
        <div class="block">
          <img src="./assets/img/personification-icon.svg">
          <h3>Personification</h3>
        </div>
        <div class="block-hover">
          <h3>Personification</h3>
          <div class="hover-text">
            Create personas that represent your target audience and see your brand from their unique perspective.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showBuyerJourney" (click)="goToExercise('buyerJourney')">
        <div class="block">
          <img src="./assets/img/buyer-journey-icon-start.svg">
          <h3>Buyer Journey</h3>
        </div>
        <div class="block-hover">
          <h3>Buyer Journey</h3>
          <div class="hover-text">
            Create customized paths for your key audience segments to lead them home to your community.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showBrandVoice" (click)="goToExercise('brandVoice')">
        <div class="block">
          <img src="./assets/img/brand-voice-chart-icon-start.svg">
          <h3>Brand Voice</h3>
        </div>
        <div class="block-hover">
          <h3>Brand Voice</h3>
          <div class="hover-text">
            Develop your unique brand voice that will resonate with your audience and set you apart.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showBrandValues" (click)="goToExercise('brandValues')">
        <div class="block">
          <img src="./assets/img/brand-values-icon-start.svg">
          <h3>Brand Values</h3>
        </div>
        <div class="block-hover">
          <h3>Brand Values</h3>
          <div class="hover-text">
            Clarify the core values of your brand that will serve as clear guidelines for everything you say and do.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showBrandMessaging" (click)="goToExercise('brandMessaging')">
        <div class="block">
          <img src="./assets/img/brand-messaging-icon-start.svg">
          <h3>Brand Messaging</h3>
        </div>
        <div class="block-hover">
          <h3>Brand Messaging</h3>
          <div class="hover-text">
            Define your brand’s core messaging so that you can start engaging better with your target audience.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showBrandKeywords" (click)="goToExercise('brandKeywords')">
        <div class="block">
          <img src="./assets/img/brand-keywords-icon-start.svg">
          <h3>Brand Keywords</h3>
        </div>
        <div class="block-hover">
          <h3>Brand Keywords</h3>
          <div class="hover-text">
            Pinpoint the specific brand messages that you should either stop, continue, or start using right now.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showBrandVoiceChart" (click)="goToExercise('brandVoiceChart')">
        <div class="block">
          <img src="./assets/img/brand-voice-chart-icon-start.svg">
          <h3>Brand Voice Chart</h3>
        </div>
        <div class="block-hover">
          <h3>Brand Voice Chart</h3>
          <div class="hover-text">
            Elaborate on the key attributes of your brand’s voice to ensure that you’re always sending the right message.
          </div>
        </div>
      </div>

      <div class="prework-block" *ngIf="showImaginationDestination" (click)="goToExercise('imaginationDestination')">
        <div class="block">
          <img src="./assets/img/imagination-destination-icon-start.svg">
          <h3>Imagination Destination</h3>
        </div>
        <div class="block-hover">
          <h3>Imagination Destination</h3>
          <div class="hover-text">
            Imagine how and where you want your brand to be in 2025 to chart your way toward your desired destination.
          </div>
        </div>
      </div>

    </div>

    <div class="start-bottom">
      <span>Powered by</span><a href="https://40parables.com/" target="_blank">
        <img src="../../assets/img/40p-logo.svg">
      </a>
    </div>
  </div>

</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>
