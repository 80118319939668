import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {

  private readonly storageKey: string               = "client_jwt_token";
  private readonly userId: string                   = 'client-manager-user-id';
  private readonly userScope: string                = 'client-manager-user-scope';
  private readonly userName: string                 = 'client-manager-user-name';
  private readonly userEmail: string                = 'client-manager-user-email';
  private readonly userMeetingId: string            = 'client-manager-meeting-id';
  private readonly userMeetingDarkLogo: string      = 'client-manager-meeting-dark-logo';
  private readonly userMeetingTitle: string         = 'client-manager-meeting-title';

  constructor(
    private router: Router ) { }

  setToken (token: string){
    localStorage.setItem(this.storageKey, token);
	}

  getToken(){
		return localStorage.getItem(this.storageKey);
	}

  setUserId(id: string) {
    localStorage.setItem(this.userId, id);
  }

  getUserId() {
    return localStorage.getItem(this.userId);
  }

  setUserScope(id: string) {
    localStorage.setItem(this.userScope, id);
  }

  getUserScope() {
    return localStorage.getItem(this.userScope);
  }

  setUserName(id: string) {
    localStorage.setItem(this.userName, id);
  }

  getUserName() {
    return localStorage.getItem(this.userName);
  }

  setUserEmail(id: string) {
    localStorage.setItem(this.userEmail, id);
  }

  getUserEmail() {
    return localStorage.getItem(this.userEmail);
  }

  setUserMeetingId(id: string) {
    localStorage.setItem(this.userMeetingId, id);
  }

  getMeetingId() {
    return localStorage.getItem(this.userMeetingId);
  }

  setUserMeetingDarkLogo(id: string) {
    localStorage.setItem(this.userMeetingDarkLogo, id);
  }

  getUserMeetingDarkLogo() {
    return localStorage.getItem(this.userMeetingDarkLogo);
  }

  setUserMeetingTitle(id: string) {
    localStorage.setItem(this.userMeetingTitle, id);
  }

  getUserMeetingTitle() {
    return localStorage.getItem(this.userMeetingTitle);
  }

  isLoggedIn (){
		return this.getToken() !== null;
	}

	logout(){
    localStorage.removeItem(this.storageKey);
    localStorage.removeItem(this.userId);
    localStorage.removeItem(this.userScope);
    localStorage.removeItem(this.userName);
    localStorage.removeItem(this.userEmail);
    localStorage.removeItem(this.userMeetingId);
    localStorage.removeItem(this.userMeetingDarkLogo);
    localStorage.removeItem(this.userMeetingTitle);
		this.router.navigate(['/login']);
  }

}
