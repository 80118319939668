<div class="cs-tabs bold-version brand-voice-chart" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          Decide what your brand will sound like when <br/>
          you communicate with your audience.
        </div>
        <div class="video-frame">
          <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
            <source src="https://cornerstone.40parables.com/wp-content/uploads/2021/06/Brand-Voice-Chart.mp4"
              type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame">
        <div class="question-content" *ngIf="!showThankYou">
          <!-- input field -->
          <!-- <div class="top-input-field">
            Your name:
            <mat-form-field >
              <mat-label>Type in your name</mat-label>
              <input
                placeholder="Type in your name"
                matInput
                [matAutocomplete]="auto"
                [(ngModel)]="memberSearch" (ngModelChange)="filteredOptions = filterMember(memberSearch)"
                >
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let member of filteredOptions" [value]="member">
                    {{ member.name }}
                </mat-option>
                <mat-option *ngIf="!hideAddNewOption" [value]="99999999" (onSelectionChange)="addMember()"><b>Add: {{ getNewMember }}</b></mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </div> -->

          <!-- brand-voice-chart tabs -->
          <div class="brand-voice-chart-tabs">
            <!-- title -->
            <div class="question-heading">
              Please answer these questions and <br/>
              expand on the voice words you chose.
            </div>
            <!-- questions -->
            <mat-tab-group  animationDuration="0ms" [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChanged($event)">
              <mat-tab label="{{ voiceChart.tab.toUpperCase() }}" *ngFor="let voiceChart of voiceChartData; let i = index">
                <div class="questions-wrapper">
                  <div class="question-block">
                    <div class="question-block-title">What other similar terms or synonyms come to mind when you think of this word?</div>
                    <mat-form-field appearance="outline">
                      <textarea matInput placeholder="Please type your answer here" [(ngModel)]="voiceChart.answers[0]"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="questions-wrapper">
                  <div class="question-block">
                    <div class="question-block-title">In your opinion, how does {{ voiceChart.tab }} describe your brand's voice?</div>
                    <mat-form-field appearance="outline">
                      <textarea matInput placeholder="Please type your answer here" [(ngModel)]="voiceChart.answers[1]"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="questions-wrapper">
                  <div class="question-block">
                    <div class="question-block-title">How should {{ voiceChart.tab }} be used?</div>
                    <mat-form-field appearance="outline">
                      <textarea matInput placeholder="Please type your answer here" [(ngModel)]="voiceChart.answers[2]"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="questions-wrapper">
                  <div class="question-block">
                    <div class="question-block-title">How should {{ voiceChart.tab }} not be used?</div>
                    <mat-form-field appearance="outline">
                      <textarea matInput placeholder="Please type your answer here" [(ngModel)]="voiceChart.answers[3]"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
            <!-- submit btn -->
            <div class="actionBtn">
              <button mat-button class="csbutton next-button" (click)="selectedIndex < maxIndex ? changeTab(selectedIndex + 1) : submitData()">
                <ng-container *ngIf="selectedIndex < maxIndex">Next</ng-container>
                <ng-container *ngIf="selectedIndex == maxIndex">Share your responses</ng-container>
              </button>
            </div>
          </div>
        </div>
        <div class="thank-you" *ngIf="showThankYou">
          <div class="heading"><span class="material-icons">check_circle</span>Your responses have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br/>
            <a (click)="showForm()">Click here</a> to refresh and add more responses.
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fa fa-star"></i>
        Examples
      </ng-template>
      <div class="example-content">
        <table>
          <tr>
            <th>VOICE</th>
            <th>OTHER WORDS</th>
            <th>DESCRIPTION</th>
            <th>HOW TO USE</th>
            <th>HOW NOT TO USE</th>
          </tr>
          <tr>
            <td>Reliable</td>
            <td>Dependable<br/> Trustworthy</td>
            <td>We stand by our products/services, and our customers can count on us to give them what they need, when they need it.</td>
            <td>Be honest and show integrity. Own any mistakes you make. Inspire confidence in your brand.</td>
            <td>Don’t oversell reliability with words; demonstrate it with action. Don’t leave issues unresolved or fail to respond to your customers’ messages.</td>
          </tr>
          <tr>
            <td>Friendly</td>
            <td>Relatable<br/> Approachable</td>
            <td>We aren’t inaccessible or hasty in our conversations. We don’t bite and are fun to be around.</td>
            <td>Be warm and inviting. Keep it fun and conversational. Speak in first and second person (you, we, us).</td>
            <td>Don’t be overly casual or use too much slang. Avoid being overfamiliar.</td>
          </tr>
          <tr>
            <td>Smart</td>
            <td>Clever<br/> Intelligent</td>
            <td>Conversations with us will leave people interested, impressed, or inspired.</td>
            <td>Know what you’re saying, and communicate with complete clarity and confidence.</td>
            <td>Stay away from technical jargon as much as possible. Try not to sound preachy or arrogant.</td>
          </tr>
          <tr>
            <td>Passionate</td>
            <td>Driven<br/> Fervent</td>
            <td>We pour everything into what we do, till the last drop. We speak with vigour and serve with fervour.</td>
            <td>Be purposeful and show emotion and enthusiasm. Use the active voice as much as possible.</td>
            <td>Rein in your passion to avoid being brash or blunt. Don’t sound wishy-washy or indecisive.</td>
          </tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>

