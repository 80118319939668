import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { forkJoin } from 'rxjs';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { Title } from '@angular/platform-browser';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-golden-pyramid',
  templateUrl: './golden-pyramid.component.html',
  styleUrls: ['./golden-pyramid.component.scss']
})
export class GoldenPyramidComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;
  isSubmitting                = false;

  clientSlug: string;
  clientId: number;
  meetingId: number;
  preworkVideo: string;

  pyramids: any;

  whys                        = [];
  hows                        = [];
  whats                       = [];

  goldenPyramidData           = [
    {
      id: 1, tab: 'WHY',
      questions: [
        { id: 1, question: 'Why does your brand exist? What is your raison d\'etre?', subTitle:'Help us understand what your brand will need to achieve for you to happily retire.', answer:'', responses: [] },
      ]
    },
    {
      id: 2, tab: 'HOW',
      questions: [
        { id: 2, question: 'How does your brand fulfill its why?', subTitle:'Tell us how you will achieve your vision.', answer:'', responses: [] },
      ]
    },
    {
      id: 3, tab: 'WHAT',
      questions: [
        { id: 3, question: 'What do you offer your prospects?', subTitle:'Help explain what your brand does to a 10-year-old.', answer:'', responses: [] },
      ]
    },
  ];

  @ViewChild('responsesInput', {static: false}) responsesInput: ElementRef<HTMLInputElement>;
  @ViewChild('responsesChipList', {static: false}) responsesChipList;

  @ViewChild('stepper', { static: false }) stepper : MatStepper;
  currentIndex            = 1;
  maxIndex                = -1;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title,
    private auth: AuthService ) {
      this.titleService.setTitle("Golden Pyramid | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    let meetingapi              = this.api.get('v1/meetings/slug/' + this.clientSlug);
    let accountapi              = this.api.get('v2/accounts/clients/' + this.auth.getMeetingId() + '/settings');
    
    forkJoin([ meetingapi, accountapi ])
      .subscribe(results => {
        console.log(results);
        if(results[0].status == "success" && results[1].status == "success") {

          this.clientId         = results[0].clientId;
          this.meetingId        = results[0].meetingId;

          this.maxIndex         = this.goldenPyramidData.length;

          let video             = results[1].data.find( function (obj) { return obj.setting == "golden_pyramid_prework_video";});
          if(video) {
            this.preworkVideo   = video.video;
          }

          this.api.get('v1/meetings/' + this.meetingId + '/goldenPyramidResponse/byUser/' + this.auth.getUserId())
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {

                this.pyramids   = data.data;

                let whys        = this.pyramids.filter( function (obj) { return obj.section == 'why'});
                let hows        = this.pyramids.filter( function (obj) { return obj.section == 'how'});
                let whats       = this.pyramids.filter( function (obj) { return obj.section == 'what'});
                
                whys.forEach(why => {
                  this.whys.push(why.response);
                });
                hows.forEach(how => {
                  this.hows.push(how.response);
                });
                whats.forEach(what => {
                  this.whats.push(what.response);
                });

                this.goldenPyramidData[0].questions[0].responses  = this.whys;
                this.goldenPyramidData[1].questions[0].responses  = this.hows;
                this.goldenPyramidData[2].questions[0].responses  = this.whats;

                /*let responses         = data.data.sort((a, b) => a.id - b.id);
                let totalResponses    = responses.length;
                responses.forEach((response, index) => {
                  this.problemPriorityData[index].questions[0].answer = response.need;
                  this.problemPriorityData[index].questions[1].answer = response.action;
                  this.problemPriorityData[index].questions[2].answer = response.problem;
                  this.problemPriorityData[index].questions[3].answer = response.solution;
                  if(totalResponses - 1 > index) {
                    this.addAnother();
                  }
                });*/
                this.isLoading        = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

    /*this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;

          this.maxIndex         = this.goldenPyramidData.length;

          this.isLoading        = false;

          this.api.get('v1/meetings/' + this.meetingId + '/goldenPyramidResponse/byUser/' + this.auth.getUserId())
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {

                this.pyramids   = data.data;

                let whys        = this.pyramids.filter( function (obj) { return obj.section == 'why'});
                let hows        = this.pyramids.filter( function (obj) { return obj.section == 'how'});
                let whats       = this.pyramids.filter( function (obj) { return obj.section == 'what'});
                
                whys.forEach(why => {
                  this.whys.push(why.response);
                });
                hows.forEach(how => {
                  this.hows.push(how.response);
                });
                whats.forEach(what => {
                  this.whats.push(what.response);
                });

                this.goldenPyramidData[0].questions[0].responses  = this.whys;
                this.goldenPyramidData[1].questions[0].responses  = this.hows;
                this.goldenPyramidData[2].questions[0].responses  = this.whats;

                /*let responses         = data.data.sort((a, b) => a.id - b.id);
                let totalResponses    = responses.length;
                responses.forEach((response, index) => {
                  this.problemPriorityData[index].questions[0].answer = response.need;
                  this.problemPriorityData[index].questions[1].answer = response.action;
                  this.problemPriorityData[index].questions[2].answer = response.problem;
                  this.problemPriorityData[index].questions[3].answer = response.solution;
                  if(totalResponses - 1 > index) {
                    this.addAnother();
                  }
                });*/
                /*this.isLoading        = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });*/
      
  }

  removeResponse(response: string, goldenPyramidId, questionId): void {
    let goldenPyramid   = this.goldenPyramidData.find( goldenPyramidData => goldenPyramidData.id === goldenPyramidId );
    if(goldenPyramid) {
      let question      = goldenPyramid.questions.find( question => question.id === questionId );
      if(question) {
        const index = question.responses.indexOf(response);
        if (index >= 0) {
          question.responses.splice(index, 1);
        }
      }
    }
  }

  addResponse(event: any, goldenPyramidId, questionId): void {

    const input = event.input;
    const value = event.value;

    let goldenPyramid  = this.goldenPyramidData.find( goldenPyramidData => goldenPyramidData.id === goldenPyramidId );
    if(goldenPyramid) {
      let question     = goldenPyramid.questions.find( question => question.id === questionId );
      if(question) {
        if ((value || '').trim()) {
          question.responses.push(value.trim());
        }
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  moveStepper(index: number, stepper: MatStepper) {
    if(index <= this.maxIndex && index > 0) {
      stepper.selectedIndex   = index - 1;
      this.currentIndex       = index;
    }
  }

  setIndex(event, stepper: MatStepper) {
    if(event.selectedIndex < this.maxIndex && event.selectedIndex >= 0) {
      this.currentIndex       = event.selectedIndex + 1;
    }
  }

  submitData() {

    if(!this.isSubmitting) {

      this.isSubmitting           = true;

      const payload = {
        memberId: this.auth.getUserId(),
        gpData: this.goldenPyramidData,
        memberName: this.auth.getUserName()
      }
      console.log(payload);

      this.api.post('v1/meetings/' + this.meetingId + '/goldenPyramid/addMultiple', payload)
        .subscribe(data => {
          if(data.status == "success") {
            this.showThankYou     = true;
            this.isSubmitting     = false;
          } else {
            this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
          }
        }, err => {
          this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
        });

    }

  }

  showForm() {
    // reset
    // this.goldenPyramidData      = this.goldenPyramidData.map(data => ({ ...data, questions: data.questions.map(question => ({...question, answer:'', responses: [] })) }));
    this.goldenPyramidData[0].questions[0].answer   = '';
    this.goldenPyramidData[1].questions[0].answer   = '';
    this.goldenPyramidData[2].questions[0].answer   = '';
    
    // hide thank you page
    this.showThankYou                               = false;
    setTimeout(() => {this.moveStepper(1, this.stepper);}, 100);
  }

  checkDisabled() {
    if(this.isSubmitting) {
      return "csbutton next-button inactive";
    }
    return "csbutton next-button";
  }


}
