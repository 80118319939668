<div class="cs-tabs bold-version" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          Settle for nothing less than absolute clarity about who your brand is.<br>
          Aim to sum up your WHY, HOW, AND WHAT in minimal words.
        </div>
        <div class="video-frame">
          <video controls="true"  [muted]="'muted'" playsinline width="100%" poster="./assets/img/Golden_Pyramid_intro.png"  >
            <!-- <div class="thumbnail">
              <img src="./assets/img/Golden_Pyramid_intro.png">
            </div> -->
            <source
              [src]="preworkVideo"
              type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame golden-pyramid-container">
        <div class="question-heading">
          Please answer these questions with one-line responses, <br>
          and feel free to add multiple responses.
        </div>

        <mat-horizontal-stepper disableRipple="true" linear #stepper [selectedIndex]="currentIndex - 1" (selectionChange)="setIndex($event, stepper)">
            
          <ng-template matStepperIcon="edit" let-index="index">
            <span>{{ index + 1 }}</span>
          </ng-template>

          <mat-step *ngFor="let data of goldenPyramidData; let i = index">
            <ng-template matStepLabel>{{ data.tab }}</ng-template>      

            <div class="question-content" *ngIf="!showThankYou">
            <!-- <div class="question-heading">
              Please answer these questions with one-line responses, <br>
              and feel free to add multiple responses.
            </div> -->
              <div class="golden-pyramid-wrapper">
                
                <div class="questions-wrapper" *ngFor="let question of data.questions">
                  <div class="question-block">
                    <div class="question-block-title">{{ question.question }}</div>
                    <div class="question-block-label">{{ question.subTitle }}</div>
                    <mat-form-field appearance="outline" floatLabel="never">
                      <input matInput placeholder="Type in your response and hit enter to add another."
                        [(ngModel)]="question.answer" #responsesInput [matChipInputFor]="responsesChipList"
                        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addResponse($event, data.id, question.id)">
                    </mat-form-field>
                    <mat-chip-list #responsesChipList class="prework-chip">
                      <mat-chip *ngFor="let response of question.responses; let i = index" [selectable]="true"
                        [removable]="true" (removed)="removeResponse(response, data.id, question.id)">
                        {{response}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </div>
                


                <!-- submit btn -->
                <div class="actionBtn">
                  <button mat-button [disabled]="isSubmitting" [ngClass]="checkDisabled()"
                    (click)="currentIndex < maxIndex ? moveStepper(currentIndex + 1, stepper) : submitData()">
                    <ng-container *ngIf="currentIndex < maxIndex">Next</ng-container>
                    <ng-container *ngIf="currentIndex == maxIndex">Share your responses</ng-container>
                  </button>
                </div>

              </div>

            </div>

          </mat-step>

        </mat-horizontal-stepper>

        
        <div class="thank-you" *ngIf="showThankYou">
          <span class="material-icons">check_circle</span>
          <div class="heading">Your responses have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br />
            <a (click)="showForm()">Add more</a> to your response.
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>