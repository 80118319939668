<div class="cs-tabs bold-version brand-advocacy-page" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="video-content">
        Happy customers know others just like them more than we do.<br>
        How can you make your happy customers your best brand<br>
        advocates to peers in their network?
      </div>
      <div class="video-frame">
        <!-- <iframe width="677" src="https://cornerstone.40parables.com/wp-content/uploads/2021/04/Golden-Pyramid.mp4" frameborder="0" allowfullscreen></iframe> -->
        <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
          <source src="https://cornerstone.40parables.com/wp-content/uploads/2021/05/Brand-Advocacy.mp4"
            type="video/mp4">
        </video>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="question-content" *ngIf="!showThankYou">

        <!-- question tabs -->
        <div class="question-tabs">
          <mat-tab-group [(selectedIndex)]="selectedIndex" animationDuration="0ms">

            <mat-tab label="P{{ i + 1 }}" *ngFor="let item of personas; let i = index">
              <div class="question-tab-title">P{{ i + 1 }} - {{ item.persona }}</div>
              <div class="question-tab-content">{{ item.description }}</div>
              <div class="question-block">
                <div class="question-block-title">What in your opinion is this segment’s most pressing need?</div>
                <mat-form-field appearance="outline">
                  <textarea matInput placeholder="E.g. connecting with other ministries" [(ngModel)]="item.need"></textarea>
                </mat-form-field>
              </div>
              <div class="question-block">
                <div class="question-block-title">Which emotions does this segment feel when they’re not able to meet that need?</div>
                <mat-form-field appearance="outline">
                  <textarea matInput placeholder="E.g. anger, frustration" [(ngModel)]="item.emotion"></textarea>
                </mat-form-field>
              </div>
              <div class="question-block">
                <div class="question-block-title">Which opposite emotions should your solution make this segment feel?</div>
                <mat-form-field appearance="outline">
                  <textarea matInput placeholder="E.g. joy, excitement" [(ngModel)]="item.opposite"></textarea>
                </mat-form-field>
              </div>
              <div class="question-block">
                <div class="question-block-title">What would you like your happy customers to tell their peers in this segment about your brand?</div>
                <mat-form-field appearance="outline">
                  <textarea matInput placeholder="E.g. This brand helped us increase our network faster than we thought." [(ngModel)]="item.peers"></textarea>
                </mat-form-field>
              </div>
              <div class="actionBtn">
                <button mat-button class="csbutton bluebutton next-button" [class.yellowbtn]="i == personas.length - 1" (click)="i < personas.length - 1 ? selectTab(selectedIndex+1) : submit()">
                  <ng-container *ngIf="i < personas.length - 1">Next</ng-container>
                  <ng-container *ngIf="i == personas.length - 1">Share your responses</ng-container>
                </button>
              </div>
            </mat-tab>

          </mat-tab-group>
        </div>
      </div>
      <div class="thank-you" *ngIf="showThankYou">
        <div class="heading"><span class="material-icons">check_circle</span>Your response have been accepted.</div>
        <div class="content">
          Got some more thoughts you'd like to add?<br/>
          <a (click)="showForm()">Click here</a> to refresh and add more responses.
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>
