import { Component, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-brand-personality-submissions',
  templateUrl: './brand-personality-submissions.component.html',
  styleUrls: ['./brand-personality-submissions.component.scss']
})
export class BrandPersonalitySubmissionsComponent implements OnInit {

  isLoading                     = true;

  clientSlug: string;
  clientId: number;
  meetingId: number;
  members                     = [];
  responseData                = [];

  questions                   = [
    { id: 1, question: 'What did you think about your brand when he/she walked into the party?', answers: [] },
    { id: 2, question: 'What kind of people did your brand interact with the most?', answers: [] },
    { id: 3, question: 'What did your brand sound like when he/she talked?', answers: [] },
    { id: 4, question: 'What emotions did your brand evoke in you during your conversation?', answers: [] },
    { id: 5, question: 'How has your perception of your brand changed from your first impression?', answers: [] },
    { id: 6, question: 'What were the next steps you wanted to take after talking to your brand?', answers: [] }
  ];

  selectedIndex                       = 0;
  currentIndex                        = 1;
  maxIndex                            = this.questions.length;
  maxSelectedIndex                    = 0;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title  ) {
      this.titleService.setTitle("Brand Personality Submissions | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.members          = data.data;

          this.isLoading        = false;

          let question1api                  = this.api.get('v2/brand-personality/clients/' + this.meetingId + '/traits/byQuestion/1');
          let question2api                  = this.api.get('v2/brand-personality/clients/' + this.meetingId + '/traits/byQuestion/2');
          let question3api                  = this.api.get('v2/brand-personality/clients/' + this.meetingId + '/traits/byQuestion/3');
          let question4api                  = this.api.get('v2/brand-personality/clients/' + this.meetingId + '/traits/byQuestion/4');
          let question5api                  = this.api.get('v2/brand-personality/clients/' + this.meetingId + '/traits/byQuestion/5');
          let question6api                  = this.api.get('v2/brand-personality/clients/' + this.meetingId + '/traits/byQuestion/6');

          forkJoin([ question1api, question2api, question3api, question4api, question5api, question6api ])
            .subscribe(results => {
              console.log(results);
              if(
                results[0].status == "success" && results[1].status == "success" && results[2].status == "success" &&
                results[3].status == "success" && results[4].status == "success" && results[5].status == "success"
              ) {

                this.questions[0].answers  = results[0].data.sort((a,b) => (a.member_id < b.member_id) ? 1 : ((b.member_id < a.member_id) ? -1 : 0));
                this.questions[1].answers  = results[1].data.sort((a,b) => (a.member_id < b.member_id) ? 1 : ((b.member_id < a.member_id) ? -1 : 0));
                this.questions[2].answers  = results[2].data.sort((a,b) => (a.member_id < b.member_id) ? 1 : ((b.member_id < a.member_id) ? -1 : 0));
                this.questions[3].answers  = results[3].data.sort((a,b) => (a.member_id < b.member_id) ? 1 : ((b.member_id < a.member_id) ? -1 : 0));
                this.questions[4].answers  = results[4].data.sort((a,b) => (a.member_id < b.member_id) ? 1 : ((b.member_id < a.member_id) ? -1 : 0));
                this.questions[5].answers  = results[5].data.sort((a,b) => (a.member_id < b.member_id) ? 1 : ((b.member_id < a.member_id) ? -1 : 0));
                
                this.isLoading  = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });

  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : '';
    }
    return initials;
  }

  responsesByMemberId(memberId, answers) {
    if(answers && answers.length > 0) {
      let member            =  answers.find( function (obj) { return obj.member_id == memberId; });
      if(member) {
        return member;
      };
      return false;
    }
    return false;
  }

  traitsByMemberId(memberId, answers) {
    if(answers && answers.length > 0) {
      let member            =  answers.filter( function (obj) { return obj.member_id == memberId; });
      if(member) {
        return member;
      };
      return false;
    }
    return false;
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

  moveStepper(index: number, stepper: MatStepper) {
    if(index <= this.maxIndex && index > 0) {
      stepper.selectedIndex     = stepper.selectedIndex + 1;
      this.currentIndex         = stepper.selectedIndex + 1;
    }
  }

  moveStepperBack(currentIndex, stepper: MatStepper) {
    if(currentIndex <= this.maxIndex && currentIndex > 0) {
      stepper.selectedIndex       = stepper.selectedIndex - 1;
      this.currentIndex           = currentIndex - 1;
    }
  }


}
