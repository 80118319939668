<div class="cs-tabs bold-version personification-page" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          Imagine your target audience as a persona and<br>
          describe how he/she would relate to your brand.
        </div>
        <div class="video-frame">
          <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
            <source src="https://cornerstone.40parables.com/wp-content/uploads/2021/07/Golden-Pyramid-CornerStone-Prework.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame">
        <div class="question-content" *ngIf="!showThankYou">
          <div class="personification-wrapper">
            <mat-horizontal-stepper disableRipple="true" #stepper [selectedIndex]="stepperCurrentIndex - 1" (selectionChange)="setIndex($event, stepper)">
              <ng-template matStepperIcon="edit" let-index="index">
                <span class="persona-step-icon">{{ index + 1 }}</span>
              </ng-template>
              <mat-step *ngFor="let data of personificationData; let p = index; trackBy:trackByIndex ">
                <div class="persona-details">
                  <div class="persona-name">{{ data.persona }}</div>
                  <div class="persona-description">{{ data.profile }}</div>
                </div>

                <div class="tab-group-wrapper">
                  <div class="tab-group-heading">Please answer these questions with as much detail as possible.</div>
                  <mat-tab-group  animationDuration="0ms" [selectedIndex]="tabGroupSelectedIndex" (selectedTabChange)="onTabChanged($event)">
                    <mat-tab label="{{ tab.tab.toUpperCase() }}" *ngFor="let tab of data.tabs; let t = index; trackBy:trackByIndex ">
                      <div class="questions-wrapper" *ngFor="let question of tab.questions; let q = index; trackBy:trackByIndex ">
                        <div class="question-block">
                          <div class="question-block-title">{{ question.question }}</div>
                          <mat-form-field appearance="outline">
                            <textarea matInput placeholder="Type your response here." [ngModel]="getAnswer(p,t,q)" (change)="saveAnswer($event, data.id, t, q)"></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                  <!-- submit btn -->
                  <div class="actionBtn">
                    <button mat-button class="csbutton next-button" (click)="moveSet(data.id, tabGroupSelectedIndex, stepperCurrentIndex)">
                      <ng-container *ngIf="tabGroupSelectedIndex < tabGroupMaxIndex">Next</ng-container>
                      <ng-container *ngIf="stepperCurrentIndex == personificationData.length && tabGroupSelectedIndex == tabGroupMaxIndex">Share your responses</ng-container>
                      <ng-container *ngIf="tabGroupSelectedIndex == tabGroupMaxIndex && stepperCurrentIndex != personificationData.length">Save</ng-container>
                    </button>
                  </div>
                </div>
             

              </mat-step>
            </mat-horizontal-stepper>
          </div>
        </div>
        <div class="thank-you" *ngIf="showThankYou">
          <span class="material-icons">check_circle</span>
          <div class="heading">Your responses have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br/>
            <a (click)="showForm()">Add more</a> to your response.
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>

