import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DUMMY_TEXT } from './dummy-text.const';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit {

  @Input() lightboxTemplate: string;
  @Input() lightboxWidth: string;
  @Input() lightboxInfo: any;
  
  @Output() onClose   = new EventEmitter();
  dummyText: string   = DUMMY_TEXT;

  constructor() { }

  ngOnInit() {
  }
}
