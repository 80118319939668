import { Component, OnInit } from '@angular/core';

@Component({
  template: '<div style="height: 200px;"></div><h1 style="text-align: center;"><span style="font-size: 40px;">Oops!</span><br>Page Not Found</h1>'
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
