<div class="cs-tabs submissions-tab voice-and-tab-submissions" *ngIf="!isLoading">

  <div class="full-frame">

    <mat-tab-group animationDuration="0ms">
      <mat-tab>
        <ng-template mat-tab-label>
          Four-liner
        </ng-template>

        <div class="question-content">
          <div class="response-table">
            <table>
              <tr>
                <th>Participant</th>
                <th>Our target users want</th>
                <th>But its absence makes them feel</th>
                <th>Our brand voice helps them feel</th>
                <th>So that they can</th>
              </tr>
              <tr *ngFor="let fourline of fourLiners">
                <td [innerHTML]="getMemberName(fourline.member_id)"></td>
                <td [innerHTML]="fourline.want"></td>
                <td [innerHTML]="fourline.feel"></td>
                <td [innerHTML]="fourline.help"></td>
                <td [innerHTML]="fourline.can"></td>
                <!-- <td [innerHTML]="getMemberResponse(member.id, 'want')">&nbsp;</td>
                <td [innerHTML]="getMemberResponse(member.id, 'feel')">&nbsp;</td>
                <td [innerHTML]="getMemberResponse(member.id, 'help')">&nbsp;</td>
                <td [innerHTML]="getMemberResponse(member.id, 'can')">&nbsp;</td> -->
              </tr>
            </table>
          </div>
        </div>

      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          Adjectives
        </ng-template>

        <div class="question-content">
          <div class="response-table">
            <table>
              <tr>
                <th style="width: 20%">Participant</th>
                <th style="width: 80%">Adjectives</th>
              </tr>
              <tr *ngFor="let member of members">
                <td style="width: 20%">{{ member.firstName }} {{ member.lastName }}</td>
                <td style="width: 80%">
                  <span *ngFor="let adjective of showResponse(member.id); let i = index">{{ adjective.adjective }}{{i === showResponse(member.id).length - 1 ? '' : ', '}}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>

  </div>

</div>
