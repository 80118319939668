import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-brand-advocacy',
  templateUrl: './brand-advocacy.component.html',
  styleUrls: ['./brand-advocacy.component.scss']
})
export class BrandAdvocacyComponent implements OnInit {

  clientSlug: any;

  personas              = [];

  selectedIndex         = 0;

  clientId;
  meetingId: number;

  showThankYou          = false;
  isLoading             = true;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title,
    private auth: AuthService ) {
      this.titleService.setTitle("Brand Advocacy | CornerStone Prework");
     }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;

          this.api.get('v1/preworks/client/' + data.clientId + '/brandadvocacy' )
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
                this.personas   = data.data.map(persona => ({ ...persona, need: null, emotion: null, opposite: null, peers: null }));
                this.isLoading  = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }


  selectTab(index) {
    this.selectedIndex = index;
  }

  submit() {

    const payload = {
      personas : this.personas,
      memberId : this.auth.getUserId()
    };
    console.log(payload);

    this.api.post('v1/preworks/client/' + this.clientId + '/brandadvocacy', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
            this.showThankYou    = true;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
    });

  }

  showForm() {
    // reset
    this.personas              = this.personas.map(persona => ({ ...persona, need: null, emotion: null, opposite: null, peers: null }));

    // hide thank you page
    this.showThankYou           = false;
    this.selectedIndex          = 0;
  }

}
