<div class="cs-tabs bold-version" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          To solve problems, you need to first identify your prospect's needs.<br>
          You then want to understand the problems that stand in the way.<br>
          This will help you curate apt solutions that attract target segments.
        </div>
        <div class="video-frame">
          <video controls="true" [muted]="'muted'" playsinline width="100%" poster="./assets/img/Problem_priority_intro.png">
            <source
              [src]="preworkVideo"
              type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame">
        <div class="question-heading">
          Please answer these questions with as much detail as possible. <br />
          Feel free to add multiple sets of responses as needed.
        </div>
        <div class="question-content" *ngIf="!showThankYou">
          <div class="problem-priority-wrapper">
            <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex"
              (selectedTabChange)="onTabChanged($event)">
              <mat-tab [label]="i + 1" *ngFor="let data of problemPriorityData; let i = index">
                <div class="questions-wrapper" *ngFor="let question of data.questions">
                  <div class="question-block">
                    <div class="question-block-title">{{ question.question }}</div>
                    <div class="question-block-label">{{ question.subHeading }}</div>
                    <mat-form-field appearance="outline">
                      <textarea matInput [placeholder]="question.placeholder" [(ngModel)]="question.answer"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
            <div class="add-another">
              <div class="footer-tab" [class.active]="selectedIndex === i" (click)="changeTab(i)"
                *ngFor="let data of problemPriorityData; let i = index">{{ i + 1 }}</div>
              <button mat-button class="csbutton bluebutton" (click)="addAnother()"><span
                  class="material-icons">add</span>Add another need</button>
            </div>
            <div class="actionBtn">
              <button mat-button [disabled]="isSubmitting" [ngClass]="checkDisabled()"
                (click)="selectedIndex < maxIndex ? changeTab(selectedIndex + 1) : submitData()">
                <ng-container *ngIf="selectedIndex < maxIndex">Next</ng-container>
                <ng-container *ngIf="selectedIndex == maxIndex">Share your responses</ng-container>
              </button>
            </div>
          </div>
        </div>
        <div class="thank-you" *ngIf="showThankYou">
          <span class="material-icons">check_circle</span>
          <div class="heading">Your responses have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br />
            <a (click)="showForm()">Add more</a> to your response.
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>