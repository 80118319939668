import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

  isLoading                         = true;

  showGoldenPyramid                 = false;
  showProblemPriority               = false;
  showSegmentation                  = false;
  showBrandAdvocacy                 = false;
  showBrandPersonality              = false;
  showPersonification               = false;
  showBuyerJourney                  = false;
  showBrandVoice                    = false;
  showBrandValues                   = false;
  showBrandMessaging                = false;
  showBrandKeywords                 = false;
  showBrandVoiceChart               = false;
  showImaginationDestination        = false;

  clientSlug: any;
  clientId: number;
  meetingName: string;
  meetingId: number;
  tools: any;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService) { }

  ngOnInit(): void {

    this.clientSlug                 = this.route.snapshot.paramMap.get('clientSlug');

    this.meetingName                = this.auth.getUserMeetingTitle();

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
            this.clientId           = data.clientId;
            this.meetingId          = data.meetingId;
            this.meetingName        = data.client;
            this.tools              = data.tools;

            this.tools.forEach(tool => {

              if(tool.title == 'Golden Pyramid') {
                if(tool.prework_state == "on") {
                  this.showGoldenPyramid      = true;
                }
              } else if(tool.title == 'Problem Priority') {
                if(tool.prework_state == "on") {
                  this.showProblemPriority    = true;
                }
              } else if(tool.title == 'Segmentation') {
                if(tool.prework_state == "on") {
                  this.showSegmentation       = true;
                }
              } else if(tool.title == 'Brand Advocacy') {
                if(tool.prework_state == "on") {
                  this.showBrandAdvocacy      = true;
                }
              } else if(tool.title == 'Brand Personality') {
                if(tool.prework_state == "on") {
                  this.showBrandPersonality   = true;
                }
              } else if(tool.title == 'Personification') {
                if(tool.prework_state == "on") {
                  this.showPersonification    = true;
                }
              } else if(tool.title == 'Buyer Journey') {
                if(tool.prework_state == "on") {
                  this.showBuyerJourney       = true;
                }
              } else if(tool.title == 'Brand Voice') {
                if(tool.prework_state == "on") {
                  this.showBrandVoice         = true;
                }
              } else if(tool.title == 'Brand Values') {
                if(tool.prework_state == "on") {
                  this.showBrandValues        = true;
                }
              } else if(tool.title == 'Brand Messaging') {
                if(tool.prework_state == "on") {
                  this.showBrandMessaging     = true;
                }
              } else if(tool.title == 'Brand Keywords') {
                if(tool.prework_state == "on") {
                  this.showBrandKeywords      = true;
                }
              } else if(tool.title == 'Brand Voice Chart') {
                if(tool.prework_state == "on") {
                  this.showBrandVoiceChart    = true;
                }
              } else if(tool.title == 'Imagination Destination') {
                if(tool.prework_state == "on") {
                  this.showImaginationDestination = true;
                }
              }

            });

            this.isLoading          = false;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  goToExercise(tool) {

    if(tool == "goldenPyramid") {
      if(this.clientSlug == "cmc") {
        window.location.href = "https://cornerstone.40parables.com/prework/cmc/golden-pyramid/";
      } else {
        this.router.navigate([this.clientSlug + '/golden-pyramid']);
      }
    } else if(tool == "problemPriority") {
      if(this.clientSlug == "cmc") {
        window.location.href = "https://cornerstone.40parables.com/prework/cmc/problem-priority/";
      } else {
        this.router.navigate([this.clientSlug + '/problem-priority']);
      }
    } else if(tool == "segmentation") {
      if(this.clientSlug == "cmc") {
        window.location.href = "https://cornerstone.40parables.com/prework/cmc/segmentation/";
      } else {
        this.router.navigate([this.clientSlug + '/segmentation']);
      }
    } else if(tool == "brandAdvocacy") {
      this.router.navigate([this.clientSlug + '/brand-advocacy']);
    } else if(tool == "brandPersonality") {
      this.router.navigate([this.clientSlug + '/brand-personality']);
    } else if(tool == "personification") {
      this.router.navigate([this.clientSlug + '/personification']);
    } else if(tool == "buyerJourney") {
      this.router.navigate([this.clientSlug + '/buyer-journey']);
    } else if(tool == "brandVoice") {
      this.router.navigate([this.clientSlug + '/brand-voice']);
    } else if(tool == "brandValues") {
      this.router.navigate([this.clientSlug + '/brand-values']);
    } else if(tool == "brandMessaging") {
      this.router.navigate([this.clientSlug + '/brand-messaging']);
    } else if(tool == "brandKeywords") {
      this.router.navigate([this.clientSlug + '/keywords']);
    } else if(tool == "brandVoiceChart") {
      this.router.navigate([this.clientSlug + '/brand-voice-chart']);
    } else if(tool == "imaginationDestination") {
      this.router.navigate([this.clientSlug + '/imagination-destination']);
    }

  }

}
