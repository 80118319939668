import { Routes, RouterModule } from "@angular/router";

// App Component.
// -------------
import { LoginComponent } from "./login/login.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { StartComponent } from "./start/start.component";
import { BrandAdvocacyComponent } from "./brand-advocacy/brand-advocacy.component";
import { BrandMessagingComponent } from "./brand-messaging/brand-messaging.component";
import { BrandValuesComponent } from "./brand-values/brand-values.component";
import { KeywordsComponent } from "./keywords/keywords.component";
import { BrandVoiceChartComponent } from "./brand-voice-chart/brand-voice-chart.component";
import { BrandPersonalityComponent } from "./brand-personality/brand-personality.component";
import { VoiceAndToneComponent } from "./voice-and-tone/voice-and-tone.component";

import { BrandPersonalitySubmissionsComponent } from './brand-personality/brand-personality-submissions/brand-personality-submissions.component';
import { VoiceAndToneSubmissionsComponent } from './voice-and-tone/voice-and-tone-submissions/voice-and-tone-submissions.component';
import { BrandValuesSubmissionsComponent } from './brand-values/brand-values-submissions/brand-values-submissions.component';
import { BrandAdvocacySubmissionsComponent } from './brand-advocacy/brand-advocacy-submissions/brand-advocacy-submissions.component';
import { BrandMessagingSubmissionsComponent } from './brand-messaging/brand-messaging-submissions/brand-messaging-submissions.component';
import { KeywordsSubmissionsComponent } from './keywords/keywords-submissions/keywords-submissions.component';
import { GoldenPyramidComponent } from './golden-pyramid/golden-pyramid.component';
import { ProblemPriorityComponent } from "./problem-priority/problem-priority.component";
import { SegmentationComponent } from "./segmentation/segmentation.component";
import { ProblemPrioritySubmissionsComponent } from "./problem-priority/problem-priority-submissions/problem-priority-submissions.component";
import { SegmentationSubmissionsComponent } from "./segmentation/segmentation-submissions/segmentation-submissions.component";
import { GoldenPyramidSubmissionsComponent } from "./golden-pyramid/golden-pyramid-submissions/golden-pyramid-submissions.component";
import { BrandVoiceChartSubmissionsComponent } from "./brand-voice-chart/brand-voice-chart-submissions/brand-voice-chart-submissions.component";
import { ImaginationDestinationSubmissionsComponent } from "./imagination-destination/imagination-destination-submissions/imagination-destination-submissions.component";
import { ImaginationDestinationComponent } from "./imagination-destination/imagination-destination.component";
import { PersonificationSubmissionsComponent } from "./personification/personification-submissions/personification-submissions.component";
import { PersonificationComponent } from "./personification/personification.component";

import { BrandVoiceComponent } from "./brand-voice/brand-voice.component";
import { BrandVoiceSubmissionsComponent } from "./brand-voice/brand-voice-submissions/brand-voice-submissions.component";

export const router: Routes = [

    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: ':clientSlug/start', component: StartComponent },

    { path: ':clientSlug/brand-advocacy', component: BrandAdvocacyComponent },
    { path: ':clientSlug/brand-advocacy/submissions', component: BrandAdvocacySubmissionsComponent },

    { path: ':clientSlug/brand-messaging', component: BrandMessagingComponent },
    { path: ':clientSlug/brand-messaging/submissions', component: BrandMessagingSubmissionsComponent },

    { path: ':clientSlug/brand-values', component: BrandValuesComponent },
    { path: ':clientSlug/brand-values/submissions', component: BrandValuesSubmissionsComponent },

    { path: ':clientSlug/keywords', component: KeywordsComponent },
    { path: ':clientSlug/keywords/submissions', component: KeywordsSubmissionsComponent },

    { path: ':clientSlug/brand-voice-chart', component: BrandVoiceChartComponent },
    { path: ':clientSlug/brand-voice-chart/submissions', component: BrandVoiceChartSubmissionsComponent },

    { path: ':clientSlug/brand-personality', component: BrandPersonalityComponent },
    { path: ':clientSlug/brand-personality/submissions', component: BrandPersonalitySubmissionsComponent },

    { path: ':clientSlug/brand-voice', component: BrandVoiceComponent },
    { path: ':clientSlug/brand-voice/submissions', component: BrandVoiceSubmissionsComponent },

    { path: ':clientSlug/golden-pyramid', component: GoldenPyramidComponent },
    { path: ':clientSlug/golden-pyramid/submissions', component: GoldenPyramidSubmissionsComponent },

    { path: ':clientSlug/problem-priority', component: ProblemPriorityComponent },
    { path: ':clientSlug/problem-priority/submissions', component: ProblemPrioritySubmissionsComponent },

    { path: ':clientSlug/segmentation', component: SegmentationComponent },
    { path: ':clientSlug/segmentation/submissions', component: SegmentationSubmissionsComponent },

    { path: ':clientSlug/imagination-destination', component: ImaginationDestinationComponent },
    { path: ':clientSlug/imagination-destination/submissions', component: ImaginationDestinationSubmissionsComponent },

    { path: ':clientSlug/personification', component: PersonificationComponent },
    { path: ':clientSlug/personification/submissions', component: PersonificationSubmissionsComponent },

    { path: '**', component: PageNotFoundComponent }

];

export const routes = RouterModule.forRoot(router, { relativeLinkResolution: 'legacy' });
