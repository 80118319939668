import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LightboxService {
  
  showLightbox: boolean;
  lightboxTemplate: string;
  
  private width: string = "";

  constructor() { 
    this.showLightbox     = false;
    this.lightboxTemplate = "";
  }

  set lightboxWidth(width: string) {
    this.width = width;
  }

  get lightboxWidth(): string {
    return this.width || "25vw";
  }

}
