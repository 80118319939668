<div class="cs-tabs submissions-tab brand-voice-chart-submissions new-submissions-design" *ngIf="!isLoading">

  <div class="full-frame">

    <div class="submissions-title">Responses</div>

    <mat-tab-group animationDuration="0ms">
      <mat-tab *ngFor="let data of responseData">

        <div class="question-content">
          <ng-template mat-tab-label >
            <span [innerHTML]="makeUppercase(data.adjective)"></span>
          </ng-template>
          <div class="response-table">
            <table>
              <tr>
                <th>&nbsp;</th>
                <th>Synonyms</th>
                <th>Description</th>
                <th>How to use</th>
                <th>How not to use</th>
              </tr>
              <tr *ngFor="let data of data.charts; let i = index">
                <td>
                  <div class="initial" [matTooltip]="showMemberHover(data.member_id)">{{ addInitials(data.member_id) }}</div>
                </td>
                <td>{{ data.synonyms }}</td>
                <td>{{ data.description }}</td>
                <td>{{ data.how_to_use }}</td>
                <td>{{ data.how_not_to_use }}</td>
              </tr>
            </table>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>

  </div>

</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>
