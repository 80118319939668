import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-brand-personality',
  templateUrl: './brand-personality.component.html',
  styleUrls: ['./brand-personality.component.scss']
})
export class BrandPersonalityComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;

  clientSlug: string;
  clientId: number;
  meetingId: number;

  questions                   = [
    { id: 1, question: 'What did you think about your brand when he/she walked into the party?', placeholder: 'E.g. had a friendly smile, was wearing a classy outfit, had a confident gait', answer: '', responses: []  },
    { id: 2, question: 'What kind of people did your brand interact with the most?', placeholder: 'E.g. people who were friendly, those who showed interest', answer: '', responses: []  },
    { id: 3, question: 'What did your brand sound like when he/she talked?', placeholder: 'E.g. sounded enthusiastic, spoke in an articulate manner, sounded calm', answer: '', responses: []  },
    { id: 4, question: 'What emotions did your brand evoke in you during your conversation?', placeholder: 'E.g. made you feel inspired, spiked your curiosity, made you feel heard', answer: '', responses: []  },
    { id: 5, question: 'How has your perception of your brand changed from your first impression?', placeholder: 'E.g. friendlier than expected, was ok with being vulnerable, not as shallow', answer: '', responses: []  },
    { id: 6, question: 'What were the next steps you wanted to take after talking to your brand?', placeholder: 'E.g. look them up on LinkedIn, exchange business cards, call them', answer: '', responses: []  }
  ];

  currentIndex                        = 1;
  maxIndex                            = this.questions.length - 1;
  @ViewChild('stepper', { static: false }) stepper : MatStepper;
  @ViewChild('responsesInput', {static: false}) responsesInput: ElementRef<HTMLInputElement>;
  @ViewChild('responsesChipList', {static: false}) responsesChipList;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title,
    private auth: AuthService ) {
      this.titleService.setTitle("Brand Personality | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.isLoading        = false;

          this.api.get('v2/brand-personality/clients/' + this.meetingId + '/traits/byUser/' + this.auth.getUserId())
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {

                data.data.forEach(trait => {
                  let question    = this.questions.find( function (obj) { return obj.id == trait.question_id; });
                  if(question) {
                    question.responses.push(trait.trait);
                  }
                });

                // this.brandMessagingData[0].answer = data?.data?.problem;
                // this.brandMessagingData[1].answer = data?.data?.solution;
                // this.brandMessagingData[2].answer = data?.data?.outcome;

                this.isLoading    = false;

              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });
  }


  submitForm() {

    let traits         = [];

    console.log(this.questions);

    this.questions.forEach(trait => {
      trait.responses.forEach(t => {
        traits.push({
          qid: trait.id,
          trait: t
        });
      });
    });

    const payload = {
      memberId : this.auth.getUserId(),
      traits: traits
    };
    console.log(payload);

    this.api.post('v2/brand-personality/clients/' + this.meetingId + '/prework', payload)
      .subscribe(data => {
        if(data.status == "success") {
          this.showThankYou     = true;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  showForm() {
    // reset

    this.questions              = this.questions.map(question => ({...question, answer:'' }));
    // hide thank you page
    this.currentIndex           = 1;
    this.showThankYou           = false;

  }



  trackByIndex(index,value){
    return index;
  }

  moveStepper(index: number, stepper: MatStepper) {
    console.log(this.currentIndex);
    console.log(index);
    if((stepper.selectedIndex < this.maxIndex) && index > 0) {
      stepper.selectedIndex     = stepper.selectedIndex + 1;
      this.currentIndex         = stepper.selectedIndex + 1;
    }
  }

  moveStepperBack(currentIndex, stepper: MatStepper) {
    if(currentIndex > 1 && currentIndex <= 20) {
      stepper.selectedIndex       = stepper.selectedIndex - 1;
      this.currentIndex           = stepper.selectedIndex + 1;
    }
  }

  checkLeftArrowActive(index) {
    if(index == 1) {
      return "brand-values-arrows inactive-arrow";
    }
    return "brand-values-arrows";
  }

  checkRightArrowActive(index) {
    if(index == this.questions.length) {
      return "brand-values-arrows inactive-arrow";
    }
    return "brand-values-arrows";
  }

  removeResponse(response: string, questionId): void {
    let question      = this.questions.find( question => question.id === questionId );
    if(question) {
      const index = question.responses.indexOf(response);
      if (index >= 0) {
        question.responses.splice(index, 1);
      }
    }
  }

  addResponse(event: any, questionId): void {

    const input = event.input;
    const value = event.value;

    let question     = this.questions.find( question => question.id === questionId );
    if(question) {
      if ((value || '').trim()) {
        question.responses.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

}
