import { Component, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-imagination-destination-submissions',
  templateUrl: './imagination-destination-submissions.component.html',
  styleUrls: ['./imagination-destination-submissions.component.scss']
})
export class ImaginationDestinationSubmissionsComponent implements OnInit {

  isLoading                     = true;

  members                       = [];
  responseData                  = [];
  clientSlug: any;
  clientId: number;
  meetingId: number;

  questions                   = [
    { id: 1, field: 'question1', question: 'Where is your organization headquartered?' },
    { id: 2, field: 'question2', question: 'Does your organization have offices apart from your headquarters? If yes, approximately how many and where are they located (especially if global)?' },
    { id: 3, field: 'question3', question: 'How many people are employed in your organization?' },
    { id: 4, field: 'question4', question: 'What does your leadership team look like?' },
    { id: 5, field: 'question5', question: 'What does your board look like?' },
    { id: 6, field: 'question6', question: 'How many past customers do you have?' },
    { id: 7, field: 'question7', question: 'How many current customers do you have?' },
    { id: 8, field: 'question8', question: 'What are your different revenue streams?' },
    { id: 9, field: 'question9', question: 'What is your annual revenue from customers (if applicable)?' },
    { id: 10, field: 'question10', question: 'What is your annual revenue from donations (if applicable)?' },
    { id: 11, field: 'question11', question: 'What percentage of your annual revenue are you spending on sales and marketing efforts?' },
    { id: 12, field: 'question12', question: 'What percentage of your team size is in your sales and marketing teams?' },
    { id: 13, field: 'question13', question: 'What percentage of your annual revenue are you spending on technology?' },
    { id: 14, field: 'question14', question: 'What percentage of your team size is in your technology team?' },
    { id: 15, field: 'question15', question: 'What are some top features of your technology that spring to mind?' },
    { id: 16, field: 'question16', question: 'What are some current features that you are really happy about?' },
    { id: 17, field: 'question17', question: 'What are some features that you plan to add soon to your software?' },
    { id: 18, field: 'question18', question: 'Which other brands are competing with you at this point?' },
    { id: 19, field: 'question19', question: 'How different is your software/technology from theirs?' },
    { id: 20, field: 'question20', question: 'What is the one thing you did right in 2021 that helped you get to where you are today in 2025?' }
  ];

  selectedIndex                       = 0;
  currentIndex                        = 1;
  maxIndex                            = this.questions.length;
  maxSelectedIndex                    = 0;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Imagination Destination Submissions | CornerStone Prework");
  }

  ngOnInit(): void {
    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.members          = data.data;

          this.api.get('v1/meetings/' + this.meetingId + '/imaginationDestinationResponses')
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
                this.responseData         = data.data;
                this.isLoading            = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });
  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : '';
    }
    return initials;
  }

  responsesByMemberId(memberId, field) {
    let data =  this.responseData.filter( function (obj) { return obj.member_id == memberId; });
    if(data[0]) return data[0][field];
    return "";
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

  moveStepper(index: number, stepper: MatStepper) {
    if(index <= this.maxIndex && index > 0) {
      stepper.selectedIndex     = stepper.selectedIndex + 1;
      this.currentIndex         = stepper.selectedIndex + 1;
    }
  }

  moveStepperBack(currentIndex, stepper: MatStepper) {
    if(currentIndex <= this.maxIndex && currentIndex > 0) {
      stepper.selectedIndex       = stepper.selectedIndex - 1;
      this.currentIndex           = currentIndex - 1;
    }
  }

}
