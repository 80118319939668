import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-brand-voice',
  templateUrl: './brand-voice.component.html',
  styleUrls: ['./brand-voice.component.scss']
})
export class BrandVoiceComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;
  isSubmitting                = false;

  clientSlug: string;
  clientId: number;
  meetingId: number;

  questionWant: string;
  questionFeel: string;
  questionHelp: string;
  questionCan: string;

  voiceToneData               = [
    { question: 'Our target users want', answer: '' },
    { question: 'but its absence makes them feel', answer: '' },
    { question: 'Our brand voice helps them feel', answer: '' },
    { question: 'so that they can', answer: '' },
  ];

  voiceToneAdjectives         = [
    { adjId: 1, adjective: '' }
  ];

  /* keywords */
  adj                               = [];
  adjectives                        = [];
  keywordInput;
  @ViewChild('keywordsInput', {static: false}) keywordsInput: ElementRef<HTMLInputElement>;
  @ViewChild('keywordschipList', {static: false}) keywordschipList;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title,
    private auth: AuthService ) {
      this.titleService.setTitle("Brand Voice | CornerStone Prework");
    }

  ngOnInit(): void {
    this.clientSlug                 = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId             = data.clientId;
          this.meetingId            = data.meetingId;
          
          this.api.get('v1/meetings/' + this.meetingId + '/voicetone/fourLiners/byMember/' + this.auth.getUserId())
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {

                if(data.data) {
                  this.questionWant   = data.data.want;
                  this.questionFeel   = data.data.feel;
                  this.questionHelp   = data.data.help;
                  this.questionCan    = data.data.can;
                }

                this.api.get('v1/meetings/' + this.meetingId + '/voicetone/adjectives/member/' + this.auth.getUserId())
                  .subscribe(data => {
                    console.log(data);
                    if(data.status == "success") {

                      this.adj        = data.data;
                      this.adj.forEach(adj => {
                        this.adjectives.push(adj.adjective);
                      });
                      this.isLoading  = false;

                    } else {
                      this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
                    }
                  }, err => {
                    this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
                  });

                this.isLoading  = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });
  }

  submitForm() {

    if(!this.isSubmitting) {

      this.isSubmitting         = true;

      const payload = {
        memberId : this.auth.getUserId(),
        // adjectives: this.voiceToneAdjectives.filter(function (obj) { return obj.adjective != ""; }),
        adjectives: this.adjectives,
        questionWant: this.questionWant,
        questionFeel: this.questionFeel,
        questionHelp: this.questionHelp,
        questionCan: this.questionCan
      };
      console.log(payload);

      this.api.post('v1/meetings/voicetone/adjective/addPrework', payload)
        .subscribe(data => {
          if(data.status == "success") {
            this.showThankYou     = true;
            this.isSubmitting     = false;
          } else {
            this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
          }
        }, err => {
          this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
        });

    }

  }

  showForm() {
    this.keywordInput           = '';
    this.showThankYou           = false;
  }

  trackByIndex(index,value){
    return index;
  }

  addNewInput(event) {

    if(event && this.voiceToneAdjectives[this.voiceToneAdjectives.length-1].adjective != '') {
      this.voiceToneAdjectives.push({ adjId: 1, adjective: '' });
    }
    
  }

  removeKeyword(adjective: string): void {
    const index = this.adjectives.indexOf(adjective);
    if (index >= 0) {
      this.adjectives.splice(index, 1);  
    }
  }

  addKeyword(event: any): void {

    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.adjectives.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

  }

  checkDisabled() {
    if(this.isSubmitting) {
      return "csbutton bluebutton next-button yellowbtn inactive";
    }
    return "csbutton bluebutton next-button yellowbtn";
  }

}
