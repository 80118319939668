<div class="cs-tabs bold-version voice-and-tone" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          Decide what your brand will sound like<br>
          when it communicates with your audience.
        </div>
        <div class="video-frame">
          <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
            <source src="https://cornerstone.40parables.com/wp-content/uploads/2021/05/Voice-Tone.mp4"
              type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame">
        <div class="question-content" *ngIf="!showThankYou">

          <!-- voice-and-tone -->
          <div class="voice-and-tone">
            <!-- title -->
            <div class="main-title">Please answer with single words or short phrases.</div>
            <div class="main-title">Complete the following sentences with words or short phrases that best define your brand's communication objective.</div>

            <div class="question-group">
              <!-- <div class="question-block" *ngFor="let data of voiceToneData; let i = index">
                <div class="question-block-title">{{ data.question }}</div>
                <mat-form-field>
                  <input matInput [(ngModel)]="data.answer" />
                </mat-form-field> -->
              <div class="question-block">
                <div class="question-block-title voice-tone">Our target users want</div>
                <mat-form-field>
                  <input matInput [(ngModel)]="questionWant" />
                </mat-form-field>
              </div>
              <div class="question-block">
                <div class="question-block-title voice-tone">but its absence makes them feel</div>
                <mat-form-field>
                  <input matInput [(ngModel)]="questionFeel" />
                </mat-form-field>
              </div>
              <div class="question-block">
                <div class="question-block-title voice-tone">Our brand voice helps them feel</div>
                <mat-form-field>
                  <input matInput [(ngModel)]="questionHelp" />
                </mat-form-field>
              </div>
              <div class="question-block">
                <div class="question-block-title voice-tone">so that they can</div>
                <mat-form-field>
                  <input matInput [(ngModel)]="questionCan" />
                </mat-form-field>
              </div>
            </div>

            <div class="question-block">
              <div class="question-block-title">Write down adjectives to best describe the way you want your brand to sound.</div>
              <!-- <mat-form-field (click)="checkMember()" floatLabel="never" *ngFor="let answer of voiceToneAdjectives; let i = index">
                <input matInput [disabled]="!memberSearch" placeholder="E.g. reliable, positive, confident, reassuring, knowledgable" [(ngModel)]="answer.adjective">
              </mat-form-field> -->
              <div class="question-block-label">E.g. reliable, positive, confident, reassuring, knowledgeable</div>
              <mat-form-field appearance="outline" floatLabel="never" >
                <input
                matInput
                placeholder="Type your response here and hit enter to add another response."
                #keywordsInput
                [(ngModel)]="keywordInput"
                [matChipInputFor]="keywordschipList"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addKeyword($event)"
                >
              </mat-form-field>
              <mat-chip-list #keywordschipList class="prework-chip">
                <mat-chip
                  *ngFor="let adjective of adjectives; let i = index"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="removeKeyword(adjective)">
                  {{ adjective }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
              <!-- <mat-form-field (click)="checkMember()" floatLabel="never" *ngFor="let answer of voiceToneAdjectives; let i = index; trackBy:trackByIndex">
                <input matInput [disabled]="!memberSearch" placeholder="E.g. reliable, positive, confident, reassuring, knowledgable" [(ngModel)]="answer.adjective" (ngModelChange)="addNewInput($event)">
              </mat-form-field> -->
              <!-- <mat-form-field appearance="outline">
                <textarea matInput placeholder="E.g. reliable, positive, confident, reassuring, knowledgable"></textarea>
              </mat-form-field> -->
            </div>

            <!-- questions -->
            <!-- <mat-accordion>
              <mat-expansion-panel *ngFor="let voiceChart of brandPData; let i = index" [expanded]="i === 0" >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ voiceChart.tab }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="questions-wrapper" *ngFor="let question of voiceChart.questions">
                  <div class="question-block">
                    <div class="question-block-title">{{ question.question }}</div>
                    <mat-form-field floatLabel="never" *ngFor="let answer of question.answers; let i = index; trackBy:trackByIndex">
                      <input matInput [placeholder]="question.placeholder" [(ngModel)]="question.answers[i]" (ngModelChange)="addNewInput($event, voiceChart.id, question.id)">
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion> -->
            <!-- submit btn -->
            <div class="actionBtn">
              <button mat-button [disabled]="isSubmitting" [ngClass]="checkDisabled()" (click)="submitForm()">Share your responses</button>
            </div>
          </div>
        </div>
        <div class="thank-you" *ngIf="showThankYou">
          <span class="material-icons">check_circle</span>
          <div class="heading">Your responses have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br/>
            <a (click)="showForm()">Add more</a> to your response.
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fa fa-star"></i>
        Examples
      </ng-template>
      <div class="example-content">
        <table>
          <tr>
            <th>APPLE</th>
            <th>UBER</th>
            <th>HARLEY DAVIDSON</th>
            <th>COCA-COLA</th>
          </tr>
          <tr>
            <td>Clean</td>
            <td>Considerate</td>
            <td>Strong</td>
            <td>Positive</td>
          </tr>
          <tr>
            <td>Simple</td>
            <td>Simple</td>
            <td>Confident</td>
            <td>Friendly</td>
          </tr>
          <tr>
            <td>Confident</td>
            <td>Consistent</td>
            <td>Aggressive</td>
            <td>Down-to-earth</td>
          </tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>

