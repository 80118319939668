import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-personification-submissions',
  templateUrl: './personification-submissions.component.html',
  styleUrls: ['./personification-submissions.component.scss']
})
export class PersonificationSubmissionsComponent implements OnInit {

  isLoading                     = true;

  members                       = [];
  responseData                  = [];

  clientTitle:string;
  clientSlug: any;
  clientId: number;
  meetingId: number;

  goalsQuestions              = [];
  engagementQuestions         = [];
  expectationQuestions        = [];
  journeyQuestions            = [];
  tabs                        = [];

  personificationData         = [];

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Personification Submissions | CornerStone Prework");
  }

  ngOnInit(): void {

    this.clientSlug                       = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId                   = data.clientId;
          this.meetingId                  = data.meetingId;
          this.clientTitle                = data.client;
          this.members                    = data.data;

          let personas        = this.api.get('v1/meetings/' + this.meetingId + '/personas');
          let responses       = this.api.get('v1/meetings/' + this.meetingId + '/personificationResponses');

          forkJoin([personas, responses])
            .subscribe(results => {
              console.log(results);
              if(results[0].status == "success" && results[1].status == "success") {

                this.goalsQuestions         = [
                  { id: 1, question: '1. What are the goals of your target persona?', field: 'goal1' },
                  { id: 2, question: '2. What is hindering this persona from achieving these goals?', field: 'goal2' },
                ];

                this.engagementQuestions    = [
                  { id: 1, question: '1. Why has this persona probably not known about ' + this.clientTitle + ' so far?', field: 'engagement1'  },
                  { id: 2, question: '2. What are some reasons why this persona might not be engaging with ' + this.clientTitle + '?', field: 'engagement2' },
                  { id: 3, question: '3. How would you describe ' + this.clientTitle + ' in 30 seconds to this persona?', field: 'engagement3' },
                  { id: 4, question: '4. What’s the biggest reason why this persona might consider engaging with ' + this.clientTitle + '?', field: 'engagement4' },
                  { id: 5, question: '5. What should this persona feel like when engaging with ' + this.clientTitle + '?', field: 'engagement5' },
                ];

                this.expectationQuestions   = [
                  { id: 1, question: '1. What would this persona expect from a brand like ' + this.clientTitle + '?', field: 'expectations1' },
                  { id: 2, question: '2. What’s the first step this persona can take to get involved with ' + this.clientTitle + '?', field: 'expectations2' },
                  { id: 3, question: '3. Why would this persona not truly connect with ' + this.clientTitle + '?', field: 'expectations3' },
                  { id: 4, question: '4. What can ' + this.clientTitle + ' do/say to help this persona stay connected?', field: 'expectations4'},
                ];

                this.journeyQuestions       = [
                  { id: 1, question: '1. What will it take to move this persona from being aware of ' + this.clientTitle + ' to being an advocate?', field: 'journey1'  },
                  { id: 2, question: '2. Why might this persona resist engaging with other brands and stay loyal to ' + this.clientTitle + '?', field: 'journey2'  },
                  { id: 3, question: '3. What aspect of ' + this.clientTitle + ' will always bring back this persona as a repeat/frequent user?', field: 'journey3' },
                ];

                this.tabs                    = [
                  { id: 1, tab: 'GOALS', questions: this.goalsQuestions },
                  { id: 2, tab: 'ENGAGEMENT', questions: this.engagementQuestions },
                  { id: 3, tab: 'EXPECTATION', questions: this.expectationQuestions },
                  { id: 4, tab: 'JOURNEY', questions: this.journeyQuestions },
                ];

                this.personificationData     = results[0].data.filter(persona => persona.selected === 'yes').map(persona => ({ ...persona, tabs: this.tabs }));
                this.responseData         = results[1].data;

                this.isLoading            = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });

  }

  response(memberId, personaId, field) {
    let response  = this.responseData.find( function (obj) { return obj.member_id == memberId && obj.persona_id == personaId; });
    if(response) {
      return response[field];
    }
    return "-";
  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : '';
    }
    return initials;
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

}
