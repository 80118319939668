<div class="cs-tabs submissions-tab circle-initials golden-pyramid-submissions new-submissions-design" *ngIf="!isLoading">

  <div class="full-frame">

    <div class="submissions-title">Responses</div>

    <div class="question-content golden-pyramid-container">
      <mat-horizontal-stepper disableRipple="true" linear #stepper>
        <ng-template matStepperIcon="edit" let-index="index">
          <span>{{ index + 1 }}</span>
        </ng-template>
        <mat-step *ngFor="let data of goldenPyramidData; let i = index">
          <ng-template matStepLabel>{{ data.tab }}</ng-template>
          <mat-tab-group animationDuration="0ms">
            <mat-tab *ngFor="let member of members">
              <ng-template mat-tab-label >
                <span [matTooltip]="showMemberHover(member.id)">{{ addInitials(member.id) }}</span>
              </ng-template>
              <div class="questions-wrapper">
                <div class="question-block">
                  <div class="question-block-title">{{ data.question }}</div>
                  <div class="question-results">
                    <div class="result" *ngFor="let responses of getResponse(data.tab, member.id)" [innerHTML]="responses.response"></div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-step>
      </mat-horizontal-stepper>
    </div>

  </div>

</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>
