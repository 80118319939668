<div class="cs-tabs bold-version segmentation-page" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          Among many ideal segments, which is the best fit for your brand?<br>
          Which segment needs your brand more than your brand needs it?
        </div>
        <div class="video-frame">
          <video controls="true" [muted]="'muted'" playsinline width="100%" poster="./assets/img/Segmentation_intro.png">
            <source
              [src]="preworkVideo"
              type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame">
        <div class="question-heading">
          Please answer with single words or short phrases, <br>
          and feel free to add multiple sets of responses.
        </div>
        <div class="question-content" *ngIf="!showThankYou">
          <div class="segmentation-wrapper">
            <div class="questions-wrapper">
              <div class="question-block">
                <div class="question-block-title">How can you optimally categorize your audience?</div>
                <div class="question-block-label">Please add the most relevant segments as well.</div>
              </div>
            </div>
            <div class="category-question-wrapper questions-wrapper" *ngFor="let question of categories; let i = index">
              <div class="question-block">
                <div class="category-question-title">{{ i+1 }}.&nbsp; &nbsp;
                  <mat-form-field floatLabel="never">
                    <input placeholder="Type in your category. E.g. church size" matInput
                      [(ngModel)]=" question.question">
                  </mat-form-field>
                </div>
                <mat-form-field appearance="outline" floatLabel="never">
                  <input matInput placeholder="Type a segment for this category, and hit enter to add a new segment."
                    [(ngModel)]="question.answer" #responsesInput [matChipInputFor]="responsesChipList"
                    [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addResponse($event, question.id)">
                </mat-form-field>
                <mat-chip-list #responsesChipList class="prework-chip">
                  <mat-chip *ngFor="let response of question.responses; let i = index" [selectable]="true"
                    [removable]="true" (removed)="removeResponse(response, question.id)">
                    {{response}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="add-another">
              <button mat-button class="csbutton greybutton" (click)="addAnother()"><span
                  class="material-icons">add</span>Add another category</button>
            </div>
            <div class="actionBtn">
              <button mat-button [disabled]="isSubmitting" [ngClass]="checkDisabled()" (click)="submitData()">Share your
                responses</button>
            </div>
          </div>
        </div>
        <div class="thank-you" *ngIf="showThankYou">
          <span class="material-icons">check_circle</span>
          <div class="heading">Your responses have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br />
            <a (click)="showForm()">Add more</a> to your response.
          </div>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>