<div class="cs-tabs submissions-tab circle-initials problem-priority-submissions new-submissions-design" *ngIf="!isLoading">

  <div class="full-frame">

    <div class="submissions-title">Responses</div>

    <mat-tab-group animationDuration="0ms">
      <mat-tab *ngFor="let member of members">

        <div class="question-content">
          <ng-template mat-tab-label >
            <span [matTooltip]="showMemberHover(member.id)">{{ addInitials(member.id) }}</span>
          </ng-template>
          <div class="response-table">
            <table>
              <tr>
                <th>&nbsp;</th>
                <th>NEED</th>
                <th>ACTION</th>
                <th>PROBLEM</th>
                <th>SOLUTION</th>
              </tr>
              <tr *ngFor="let data of responsesByMemberId(member.id); let i = index">
                <td>{{ i+1 }}.</td>
                <td>{{ data.need }}</td>
                <td>{{ data.action }}</td>
                <td>{{ data.problem }}</td>
                <td>{{ data.solution }}</td>
              </tr>
            </table>
          </div>
        </div>

      </mat-tab>
    </mat-tab-group>

  </div>

</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>
