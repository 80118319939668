  <div class="app-component-wrapper">

    <div [class.mobile-open-overlay]="screenWidth < 900 && menubtn" (click)="closeMobileMenu()"></div>
    <div class='mainmenu' [class.mainmenu-collapsed]="!menubtn && screenWidth > 899 && menuopen && !isPublicUrl" *ngIf="(menuopen && !isPublicUrl) || screenWidth < 900" [class.mainmenu-mobile-open]="screenWidth < 900 && menubtn" [class.mainmenu-mobile-closed]="screenWidth < 900 && !menubtn">
      <a href='javascript:void(0);' (click)="closeMenu()" class="closebtn" *ngIf="menubtn && screenWidth > 899"><img src='./assets/img/menu-close.png'></a>
      <a href='javascript:void(0);' (click)="openMenu()" class="menubtn" *ngIf="!menubtn && screenWidth > 899"><img src='./assets/img/menu-close.png'></a>
      <a href='javascript:void(0);' (click)="closeMobileMenu()" class="closebtn" *ngIf="screenWidth < 900"><img src='./assets/img/menu-close.png'></a>
      <div class="cs-logo" [style.cursor]="isHomeLink()" (click)="goToHome()">
        <div class="cs-logo-title">CornerStone</div>
        <div class="cs-logo-subtitle">Prework {{ isSubmissionPage }}</div>
      </div>
      <div class="cs-menu">
        <ul>
          <li *ngIf="showPrework('Golden Pyramid')">
            <a href="javascript:void(0);" (click)="getUrl('Golden Pyramid');closeMenuMobile();" [ngClass]="checkActiveMenu('goldenpyramid')">
              <img class="in-active" src="../assets/img/golden-pyramid-icon.svg">
              <img class="active" src="../assets/img/golden-pyramid-icon-active.svg">
              <span *ngIf="showMenu()">Golden Pyramid</span>
            </a>
          </li>
          <li *ngIf="showPrework('Problem Priority')">
            <a href="javascript:void(0);" (click)="getUrl('Problem Priority');closeMenuMobile();" [ngClass]="checkActiveMenu('problempriority')">
              <img class="in-active" src="../assets/img/problem-priority-icon.svg">
              <img class="active" src="../assets/img/problem-priority-icon-active.svg">
              <span *ngIf="showMenu()">Problem Priority</span>
            </a>
          </li>
          <li *ngIf="showPrework('Segmentation')">
            <a href="javascript:void(0);" (click)="getUrl('Segmentation');closeMenuMobile();" [ngClass]="checkActiveMenu('segmentation')">
              <img class="in-active" src="../assets/img/segmentation-icon.png">
              <img class="active" src="../assets/img/segmentation-icon-active.svg">
              <span *ngIf="showMenu()">Segmentation</span>
            </a>
          </li>
          <li *ngIf="showPrework('Brand Advocacy')">
            <a href="javascript:void(0);" (click)="getUrl('Brand Advocacy');closeMenuMobile();" [ngClass]="checkActiveMenu('brandadvocacy')">
              <img class="in-active" src="../assets/img/brand-advocacy-icon.svg">
              <img class="active" src="../assets/img/brand-advocacy-icon-active.svg">
              <span *ngIf="showMenu()">Brand Advocacy</span>
            </a>
          </li>
          <li *ngIf="showPrework('Brand Personality')">
            <a href="javascript:void(0);" (click)="getUrl('Brand Personality');closeMenuMobile();" [ngClass]="checkActiveMenu('brandpersonality')">
              <img class="in-active" src="../assets/img/brand-personality-icon.svg">
              <img class="active" src="../assets/img/brand-personality-icon-active.svg">
              <span *ngIf="showMenu()">Brand Personality</span>
            </a>
          </li>
          <li *ngIf="showPrework('Personification')">
            <a href="javascript:void(0);" (click)="getUrl('Personification');closeMenuMobile();" [ngClass]="checkActiveMenu('personification')">
              <img class="in-active" src="../assets/img/personification-icon.png">
              <img class="active" src="../assets/img/personification-icon-active.svg">
              <span *ngIf="showMenu()">Personification</span>
            </a>
          </li>
          <li *ngIf="showPrework('Buyer Journey')">
            <a href="javascript:void(0);" (click)="getUrl('Buyer Journey');closeMenuMobile();" [ngClass]="checkActiveMenu('buyerjourney')">
              <img class="in-active" src="../assets/img/buyer-journey-icon.svg">
              <img class="active" src="../assets/img/buyer-journey-icon-active.svg">
              <span *ngIf="showMenu()">Buyer Journey</span>
            </a>
          </li>
          <li *ngIf="showPrework('Brand Voice')">
            <a href="javascript:void(0);" (click)="getUrl('Brand Voice');closeMenuMobile();" [ngClass]="checkActiveMenu('brandvoice')">
              <img class="in-active" src="../assets/img/brand-voice-chart-icon.svg">
              <img class="active" src="../assets/img/brand-voice-chart-icon-active.svg">
              <span *ngIf="showMenu()">Brand Voice</span>
            </a>
          </li>
          <li *ngIf="showPrework('Brand Values')">
            <a href="javascript:void(0);" (click)="getUrl('Brand Values');closeMenuMobile();" [ngClass]="checkActiveMenu('brandvalues')">
              <img class="in-active" src="../assets/img/brand-values-icon.svg">
              <img class="active" src="../assets/img/brand-values-icon-active.svg">
              <span *ngIf="showMenu()">Brand Values</span>
            </a>
          </li>
          <li *ngIf="showPrework('Brand Messaging')">
            <a href="javascript:void(0);" (click)="getUrl('Brand Messaging');closeMenuMobile();" [ngClass]="checkActiveMenu('brandmessaging')">
              <img class="in-active" src="../assets/img/brand-messaging-icon.png">
              <img class="active" src="../assets/img/brand-messaging-icon-active.svg">
              <span *ngIf="showMenu()">Brand Messaging</span>
            </a>
          </li>
          <li *ngIf="showPrework('Brand Keywords')">
            <a href="javascript:void(0);" (click)="getUrl('Brand Keywords');closeMenuMobile();" [ngClass]="checkActiveMenu('keywords')">
              <img class="in-active" src="../assets/img/brand-keywords-icon.svg">
              <img class="active" src="../assets/img/brand-keywords-icon-active.svg">
              <span *ngIf="showMenu()">Brand Keywords</span>
            </a>
          </li>
          <li *ngIf="showPrework('Brand Voice Chart')">
            <a href="javascript:void(0);" (click)="getUrl('Brand Voice Chart');closeMenuMobile();" [ngClass]="checkActiveMenu('brandvoicechart')">
              <img class="in-active" src="../assets/img/brand-voice-chart-icon.svg">
              <img class="active" src="../assets/img/brand-voice-chart-icon-active.svg">
              <span *ngIf="showMenu()">Brand Voice Chart</span>
            </a>
          </li>
          <li *ngIf="showPrework('Imagination Destination')">
            <a href="javascript:void(0);" (click)="getUrl('Imagination Destination');closeMenuMobile();" [ngClass]="checkActiveMenu('imaginationdestination')">
              <img class="in-active" src="../assets/img/imagination-destination-icon.svg">
              <img class="active" src="../assets/img/imagination-destination-icon-active.svg">
              <span *ngIf="showMenu()">Imagination Destination</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="cs-copyright" *ngIf="showMenu()">
        <img src="./../assets/img/logo-white.svg">
      </div>
    </div>

    <div class="toolbar-wrapper" [class.toolbar-wrapper-public]="isPublicUrl" [class.toolbar-wrapper-collapsed]="!menubtn && screenWidth > 899 && menuopen && !isPublicUrl">
      <mat-toolbar class='header' *ngIf="!isPublicUrl" [class.toolbar-collapsed]="!menubtn && screenWidth > 899 && menuopen && !isPublicUrl">

        <div class="menu-button" style="flex: 0 2 40px; ">
          <a href='javascript:void(0);' (click)="openMobileMenu()" class="menubtn" *ngIf="screenWidth < 900"><i class="fa fa-bars" aria-hidden="true"></i></a>
        </div>

        <div class="epic-pay-logo" style="flex: 1 1 auto;">
          <img *ngIf="meetingLogo && meetingLogo != 'null'" [src]="meetingLogo">
          <span *ngIf="!meetingLogo || meetingLogo == 'null'">{{ meetingName }}</span>
        </div>

        <div class="header-text" style="flex: 0 1 auto;">
          <span>{{ pageHeading }}</span>
        </div>
        <div class="username" style="flex: 1 1 auto;justify-content: flex-end;">
          {{ username }} <span class="material-icons username-icon" [class.rotate]="isMenuShowing" (click)="toggleMenu()">keyboard_arrow_down</span>
          <div class="dropdown" [class.show-menu]="isMenuShowing">
            <div class="dropdown-item" (click)="logOut()">Log out</div>
          </div>
        </div>
      </mat-toolbar>

      <div class='container' [class.no-container]="isPublicUrl" [class.container-collapsed]="!menubtn && screenWidth > 899 && menuopen && !isPublicUrl">
        <router-outlet></router-outlet>
      </div>

    </div>

  </div>

  <app-lightbox *ngIf="lightbox.showLightbox"
    [lightboxTemplate]="lightbox.lightboxTemplate"
    [lightboxInfo]="lightbox.lightboxInfo"
    [lightboxWidth]="lightbox.lightboxWidth"
    (onClose)="lightbox.close()"
    (onSelectInfo)="lightbox.lightboxSelectedInfo.next($event.target)">
  </app-lightbox>

  <!-- <mat-sidenav class='aside' #asidenav mode="over" opened="false" position="end" style="height: 100vh; overflow: hidden;">
    <app-aside></app-aside>
  </mat-sidenav> -->
