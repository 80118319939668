<div class="cs-tabs submissions-tab personification-submissions new-submissions-design" *ngIf="!isLoading">

  <div class="full-frame">

    <div class="submissions-title">Responses</div>

    <div class="question-content personification-container">
      <div class="personification-wrapper">

        <mat-horizontal-stepper disableRipple="true" #stepper>
          <ng-template matStepperIcon="edit" let-index="index">
            <span>{{ index + 1 }}</span>
          </ng-template>
          <mat-step *ngFor="let data of personificationData; let p = index;">

            <div class="persona-details">
              <div class="persona-name">{{ data.persona }}</div>
              <div class="persona-description">{{ data.title }}</div>
            </div>

            <div class="tab-group-wrapper">

              <mat-tab-group animationDuration="0ms">
                <mat-tab *ngFor="let tab of tabs">

                  <div class="question-content">
                    <ng-template mat-tab-label  >
                      <span>{{ tab.tab }}</span>
                    </ng-template>
                    <div class="questions-wrapper" *ngFor="let question of tab.questions;">
                      <div class="question-block" >
                        <div class="question-block-title">{{ question.question }}</div>
                        <div class="question-results">
                          <div class="result" *ngFor="let member of members;">
                            <div class="response">
                              <div class="member" [matTooltip]="showMemberHover(member.id)">{{ addInitials(member.id) }}</div>
                              <div class="response-result">
                                {{ response(member.id, data.id, question.field) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </mat-tab>
              </mat-tab-group>
            </div>

          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>

  </div>

</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>
