<div class="cs-tabs submissions-tab brand-advocacy-submissions new-submissions-design" *ngIf="!isLoading">

  <div class="full-frame">

    <div class="submissions-title">Responses</div>

    <div class="responses-table">
      <mat-tab-group animationDuration="0ms">
        <mat-tab *ngFor="let persona of personas; let i = index">
          <ng-template mat-tab-label >
            <span>P{{ i+1 }}</span>
          </ng-template>

          <div class="persona-details">
            <div class="persona-name">{{ persona.persona }} - </div>
            <div class="persona-description">{{ persona.description }}</div>
          </div>

          <div class="question-content">
            <div class="response-table">
              <table>
                <tr>
                  <th>&nbsp;</th>
                  <th>NEED</th>
                  <th>EMOTIONS</th>
                  <th>DESIRE</th>
                  <th>RECOMMENDATION</th>
                </tr>
                <tr *ngFor="let data of responsesByPersonaId(persona.id)">
                  <td class="response-persona">
                    <span class="member" [matTooltip]="showMemberHover(data.member_id)">{{ addInitials(data.member_id) }}</span><br>
                  </td>
                  <td>{{ data.need }}</td>
                  <td>{{ data.emotion }}</td>
                  <td>{{ data.opposite }}</td>
                  <td>{{ data.peers }}</td>
                </tr>
              </table>
            </div>
          </div>

        </mat-tab>
      </mat-tab-group>
    </div>

  </div>


</div>

