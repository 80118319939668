import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';
import { MatStepper } from '@angular/material/stepper';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-brand-values',
  templateUrl: './brand-values.component.html',
  styleUrls: ['./brand-values.component.scss']
})
export class BrandValuesComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;

  clientSlug: string;
  clientId: number;
  meetingId: number;

  coreValues: any;
  importantValues: any;
  sacred: any;
  topThreeRules: any;
  setsUsApart: any;
  mattersMost: any;
  mightMatter: any;
  workLookLike: any;
  refuseToCompromise: any;
  rememberedFor: any;

  questions                   = [
    { id: 1, question: 'What are some core values that guide your personal lives?', placeholder: 'E.g. loyalty, honesty', answer: '', responses: []  },
    { id: 2, question: 'What are the most important values a brand must preserve?', placeholder: 'E.g. integrity, excellence', answer: '', responses: []  },
    { id: 3, question: 'What should your brand consider sacred?', placeholder: 'E.g. honoring our commitment to clients', answer: '', responses: []  },
    { id: 4, question: 'What will you define as your brand\'s top 3 rules?', placeholder: 'E.g. put people first, be excellent stewards, work is worship', answer: '', responses: []  },
    { id: 5, question: 'What is it about the way you do things as a brand that sets you apart?', placeholder: 'E.g. bringing an honest and objective perspective', answer: '', responses: []  },
    { id: 6, question: 'What is it about your brand that matters most to the employee in you?', placeholder: 'E.g. the friendly and inclusive work culture', answer: '', responses: []  },
    { id: 7, question: 'What about your brand might matter most to a client?', placeholder: 'E.g. serving with integrity and excellence', answer: '', responses: []  },
    { id: 8, question: 'What does good work look like at your brand?', placeholder: 'E.g. exceeding the client’s expectations', answer: '', responses: []  },
    { id: 9, question: 'What will your brand refuse to compromise on, even on the last day of its existence?', placeholder: 'E.g. cutting corners to get substandard results', answer: '', responses: []  },
    { id: 10, question: 'At the end of the day, what one thing will your brand be remembered for?', placeholder: 'E.g. elevating the way ministries work', answer: '', responses: []  },
  ];

  currentIndex                        = 1;
  maxIndex                            = this.questions.length - 1;
  @ViewChild('stepper', { static: false }) stepper : MatStepper;
  @ViewChild('responsesInput', {static: false}) responsesInput: ElementRef<HTMLInputElement>;
  @ViewChild('responsesChipList', {static: false}) responsesChipList;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title,
    private auth: AuthService ) {
      this.titleService.setTitle("Brand Value | CornerStone Prework");
     }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;

          this.api.get('v2/brand-values/clients/' + this.meetingId + '/byUser/' + this.auth.getUserId())
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {

                this.questions.forEach(question => {
                  let responses   = data.data.filter( function (obj) { return obj.question_id == question.id;});
                  if(responses.length > 0) {
                    responses.forEach(res => {
                      question.responses.push(res.value);
                    });
                  }
                });

                this.isLoading    = false;

              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  saveBrandValue(index) {

    let currentquestion     = index - 1;

    const payload = {
      memberId : this.auth.getUserId(),
      questionId: this.questions[currentquestion].id,
      values: this.questions[currentquestion].responses
    }

    this.api.post('v2/brand-values/clients/' + this.clientId + '/prework', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          // this.showThankYou    = true;
        } else {
          this.ui.showSnackbar("Sorry a server has occured. If this keeps happening please email us.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Oops, something went wrong. Please check your connection and make sure your responses have been saved.", "failure");
      });

    if(index < this.maxIndex + 1) {
      this.moveStepper(index + 1, this.stepper);
    } else {
      this.submitForm();
    }

  }

  submitForm() {

    const payload = {
      memberId : this.auth.getUserId()
    };

    console.log(payload);

    this.api.post('v2/brand-values/clients/' + this.clientId + '/prework/complete', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          this.showThankYou    = true;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
      });

    /*const payload = {
      memberId : this.auth.getUserId(),
      coreValues: this.coreValues,
      importantValues: this.importantValues,
      sacred: this.sacred,
      topThreeRules: this.topThreeRules,
      setsUsApart: this.setsUsApart,
      mattersMost: this.mattersMost,
      mightMatter: this.mightMatter,
      workLookLike: this.workLookLike,
      refuseToCompromise: this.refuseToCompromise,
      rememberedFor: this.rememberedFor
    };
    console.log(payload);

    this.api.post('v1/preworks/client/' + this.clientId + '/brandvalues', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
            this.showThankYou    = true;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
    });*/

  }

  showForm() {
    
    // hide thank you page
    this.currentIndex           = 1;
    this.showThankYou           = false;

  }

  moveStepper(index: number, stepper: MatStepper) {
    console.log(this.currentIndex);
    console.log(index);
    if((stepper.selectedIndex < this.maxIndex) && index > 0) {
      stepper.selectedIndex     = stepper.selectedIndex + 1;
      this.currentIndex         = stepper.selectedIndex + 1;
    }
  }

  moveStepperBack(currentIndex, stepper: MatStepper) {
    if(currentIndex > 1 && currentIndex <= 20) {
      stepper.selectedIndex       = stepper.selectedIndex - 1;
      this.currentIndex           = stepper.selectedIndex + 1;
    }
  }

  checkLeftArrowActive(index) {
    if(index == 1) {
      return "brand-values-arrows inactive-arrow";
    }
    return "brand-values-arrows";
  }

  checkRightArrowActive(index) {
    if(index == this.questions.length) {
      return "brand-values-arrows inactive-arrow";
    }
    return "brand-values-arrows";
  }

  removeResponse(response: string, questionId): void {
    let question      = this.questions.find( question => question.id === questionId );
    if(question) {
      const index = question.responses.indexOf(response);
      if (index >= 0) {
        question.responses.splice(index, 1);
      }
    }
  }

  addResponse(event: any, questionId): void {

    const input = event.input;
    const value = event.value;

    let question     = this.questions.find( question => question.id === questionId );
    if(question) {
      if ((value || '').trim()) {
        question.responses.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

}
