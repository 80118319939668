import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-golden-pyramid-submissions',
  templateUrl: './golden-pyramid-submissions.component.html',
  styleUrls: ['./golden-pyramid-submissions.component.scss']
})
export class GoldenPyramidSubmissionsComponent implements OnInit {

  isLoading                     = true;

  members                       = [];
  responseData                  = [];
  clientSlug: any;
  clientId: number;
  meetingId: number;

  goldenPyramidData           = [
    {
      id: 1, tab: 'WHY',
      question:  'Why does your brand exist? What is your raison d\'etre?',
    },
    {
      id: 2, tab: 'HOW',
      question: 'How does your brand fulfill its why?'
    },
    {
      id: 3, tab: 'WHAT',
      question: 'What do you offer your prospects?'
    },
  ];

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title ) {
      this.titleService.setTitle("Golden Pyramid Submissions | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.members          = data.data;

          this.api.get('v1/meetings/' + this.meetingId + '/GoldenPyramidResponses/multiple')
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
                this.responseData         = data.data;
                this.isLoading            = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });

  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : null;
    }
    return initials;
  }

  responsesByMemberId(memberId, tab) {
    return this.responseData.filter( function (obj) { return obj.member_id == memberId && obj.section.toLowerCase() == tab.toLowerCase(); });
  }

  getResponse(section, memberId) {
    let sec                 = section.toLowerCase();
    let response            = this.responseData.filter( function (obj) { return obj.member_id == memberId && obj.section == sec; });

    if(response) {
      return response;
    }
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      return member.firstName + " " + member.lastName;
    }
  }

  getMemberName(id) {
    let member          = this.members.find(function (obj) { return obj.id == id; })
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

}
