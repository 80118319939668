import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { forkJoin } from 'rxjs';

import { ActivatedRoute } from '@angular/router';

import { UiService } from 'src/app/shared/ui.service';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: 'app-brand-voice-submissions',
  templateUrl: './brand-voice-submissions.component.html',
  styleUrls: ['./brand-voice-submissions.component.scss']
})
export class BrandVoiceSubmissionsComponent implements OnInit {

  isLoading                     = true;

  clientSlug: string;
  clientId: number;
  meetingId: number;
  members                       = [];
  fourLiners                    = [];
  adjectives                    = [];

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title ) {
      this.titleService.setTitle("Brand Voice Submissions | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.members          = data.data;

          let fourLiners        = this.api.get('v1/preworks/' + this.clientId + '/voiceToneFourLiners')
          let adjectives        = this.api.get('v1/meetings/' + this.meetingId + '/voicetone/adjectives');

          forkJoin([fourLiners, adjectives])
            .subscribe(results => {
              console.log(results);
              if(results[0].status == "success" && results[1].status == "success") {
                this.fourLiners           = results[0].data;
                this.adjectives           = results[1].data;
                this.isLoading            = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
                this.ui.showSnackbar("Network Error, Please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  getMemberName(id) {
    let member          = this.members.find(function (obj) { return obj.id == id; })
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

  getMemberResponse(memberId, answer) {

    let fourLiners      = this.fourLiners.find(function (obj) { return obj.member_id == memberId; });
    if(fourLiners) {
      if(answer == 'want') {
        return fourLiners.want;
      } else if(answer == 'feel') {
        return fourLiners.feel;
      } else if(answer == 'help') {
        return fourLiners.help;
      } else if(answer == 'can') {
        return fourLiners.can;
      }
    }

  }

  showResponse(memberId) {
    let adjectives  = this.adjectives.filter( adjective => adjective.member_id === memberId );
    if(adjectives)
    return adjectives;
    return '';
  }

}
