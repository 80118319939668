import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { UiService } from '../shared/ui.service';

@Component({
  selector: 'app-personification',
  templateUrl: './personification.component.html',
  styleUrls: ['./personification.component.scss']
})
export class PersonificationComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;

  clientTitle:string;
  clientSlug: string;
  clientId: number;
  meetingId: number;

  goalsQuestions              = [];
  engagementQuestions         = [];
  expectationQuestions        = [];
  journeyQuestions            = [];

  tabs                        = [];

  personificationData         = [];

  @ViewChild('stepper', { static: false }) stepper : MatStepper;
  stepperCurrentIndex     = 1;
  stepperMaxIndex         = 0;

  @ViewChild('MatTabGroup', { static: false }) tabGroup : MatTabGroup;
  tabGroupSelectedIndex   = 0;
  tabGroupMaxIndex        = 0;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title,
    private auth: AuthService
  ) {
    this.titleService.setTitle("Personification | CornerStone Prework");
  }

  ngOnInit(): void {
    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientTitle      = data.client;
          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;

          this.api.get('v1/meetings/' + this.meetingId + '/personas')
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {

                this.goalsQuestions         = [
                  { id: 1, db: 'goal1', question: '1. What are the goals of your target persona?' },
                  { id: 2, db: 'goal2', question: '2. What is hindering this persona from achieving these goals?' },
                ];

                this.engagementQuestions    = [
                  { id: 1, db: 'engagement1', question: '1. Why has this persona probably not known about ' + this.clientTitle + ' so far?' },
                  { id: 2, db: 'engagement2', question: '2. What are some reasons why this persona might not be engaging with ' + this.clientTitle + '?' },
                  { id: 3, db: 'engagement3', question: '3. How would you describe ' + this.clientTitle + ' in 30 seconds to this persona?' },
                  { id: 4, db: 'engagement4', question: '4. What’s the biggest reason why this persona might consider engaging with ' + this.clientTitle + '?' },
                  { id: 5, db: 'engagement5', question: '5. What should this persona feel like when engaging with ' + this.clientTitle + '?' },
                ];

                this.expectationQuestions   = [
                  { id: 1, db: 'expectations1', question: '1. What would this persona expect from a brand like ' + this.clientTitle + '?' },
                  { id: 2, db: 'expectations2', question: '2. What’s the first step this persona can take to get involved with ' + this.clientTitle + '?' },
                  { id: 3, db: 'expectations3', question: '3. Why would this persona not truly connect with ' + this.clientTitle + '?' },
                  { id: 4, db: 'expectations4', question: '4. What can ' + this.clientTitle + ' do/say to help this persona stay connected?' },
                ];

                this.journeyQuestions       = [
                  { id: 1, db: 'journey1', question: '1. What will it take to move this persona from being aware of ' + this.clientTitle + ' to being an advocate?' },
                  { id: 2, db: 'journey2', question: '2. Why might this persona resist engaging with other brands and stay loyal to ' + this.clientTitle + '?' },
                  { id: 3, db: 'journey3', question: '3. What aspect of ' + this.clientTitle + ' will always bring back this persona as a repeat/frequent user?' },
                ];

                this.tabs                    = [
                  { id: 1, tab: 'GOALS', questions: this.goalsQuestions.map(question => ({...question, answer: null })) },
                  { id: 2, tab: 'ENGAGEMENT', questions: this.engagementQuestions.map(question => ({...question, answer: null })) },
                  { id: 3, tab: 'EXPECTATION', questions: this.expectationQuestions.map(question => ({...question, answer: null })) },
                  { id: 4, tab: 'JOURNEY', questions: this.journeyQuestions.map(question => ({...question, answer: null })) },
                ];

                this.personificationData     = data.data.filter(persona => persona.selected === 'yes').map(persona => ({ ...persona, tabs: this.tabs }));

                this.stepperMaxIndex         = this.personificationData.length;
                this.tabGroupMaxIndex        = this.tabs.length - 1;
                this.saveInitialData(data.data);
                this.isLoading               = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  moveStepper(index: number, stepper: MatStepper) {
    if(index <= this.stepperMaxIndex && index > 0) {
      stepper.selectedIndex          = index - 1;
      this.stepperCurrentIndex       = index;
    }
  }

  setIndex(event, stepper: MatStepper) {
    if(event.selectedIndex < this.stepperMaxIndex && event.selectedIndex >= 0) {
      this.stepperCurrentIndex       = event.selectedIndex + 1;
      this.tabGroupSelectedIndex     = 0;
    }
  }

  onTabChanged(event) {
    this.tabGroupSelectedIndex       = event.index;
  }

  changeTab(index) {
    this.tabGroupSelectedIndex       = index;
  }

  moveSet(id, tabindex, personaindex) {

    if(tabindex < this.tabGroupMaxIndex) {
      this.changeTab(tabindex + 1);
    } else {
      if(personaindex < this.stepperMaxIndex) {
        setTimeout(() => {this.moveStepper(personaindex + 1, this.stepper);}, 100);
      } else {
        this.submitData();
      }
    }
  }

  submitData() {

    /*let persona         = this.personificationData.find(persona => persona.id === personaId);
    let goal            = persona.tabs[0];
    let engagement      = persona.tabs[1];
    let expectations    = persona.tabs[2];
    let journey         = persona.tabs[3];*/

    const payload = {
      memberId: this.auth.getUserId()
    }

    this.api.post('v2/personification/clients/' + this.clientId + '/prework/complete', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          this.showThankYou    = true;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
      });

    /*const payload = {
      memberId: this.auth.getUserId(),
      memberName: this.auth.getUserName(),
      personaId: personaId,
      goal1: goal.questions[0].answer,
      goal2: goal.questions[1].answer,
      engagement1: engagement.questions[0].answer,
      engagement2: engagement.questions[1].answer,
      engagement3: engagement.questions[2].answer,
      engagement4: engagement.questions[3].answer,
      engagement5: engagement.questions[4].answer,
      expectations1: expectations.questions[0].answer,
      expectations2: expectations.questions[1].answer,
      expectations3: expectations.questions[2].answer,
      expectations4: expectations.questions[3].answer,
      journey1: journey.questions[0].answer,
      journey2: journey.questions[1].answer,
      journey3: journey.questions[2].answer
    }
    // console.log(this.personificationData);
    console.log(payload);
    this.api.post('v1/meetings/' + this.meetingId + '/personification/addPrework', payload)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {
          if(stepperIndex === this.personificationData.length) {
            this.showThankYou    = true;
          } else {
            setTimeout(() => {this.moveStepper(stepperIndex+1, this.stepper);}, 100);
          }
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
          this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
    });*/

  }

  saveInitialData(data) {
    let clonedData     = [...this.personificationData.map(data => ({ ...data, tabs: data.tabs.map(tab => ({ ...tab, questions: tab.questions.map(question => ({ ...question })) })) }) )];
    data.forEach(persona => {
      let result         = clonedData.find(data => data.id == persona.id);
      if(result) {
        let goal            = result.tabs[0];
        let engagement      = result.tabs[1];
        let expectations    = result.tabs[2];
        let journey         = result.tabs[3];
        let answer          = persona.answers.find(answer => answer.persona_id == result.id && answer.member_id == this.auth.getUserId());
        if(answer) {
          goal.questions[0].answer          = answer.goal1;
          goal.questions[1].answer          = answer.goal2;
          engagement.questions[0].answer    = answer.engagement1;
          engagement.questions[1].answer    = answer.engagement2;
          engagement.questions[2].answer    = answer.engagement3;
          engagement.questions[3].answer    = answer.engagement4;
          engagement.questions[4].answer    = answer.engagement5;
          expectations.questions[0].answer  = answer.expectations1;
          expectations.questions[1].answer  = answer.expectations2;
          expectations.questions[2].answer  = answer.expectations3;
          expectations.questions[3].answer  = answer.expectations4;
          journey.questions[0].answer       = answer.journey1;
          journey.questions[1].answer       = answer.journey2;
          journey.questions[2].answer       = answer.journey3;
        }
      }
    });
    this.personificationData                = clonedData;
  }

  showForm() {
    // reset

    // hide thank you page
    this.showThankYou                               = false;
    // setTimeout(() => {this.moveStepper(1, this.stepper);}, 100);
  }

  getAnswer(data, tab, question) {
    return this.personificationData[data].tabs[tab].questions[question].answer;
  }

  saveAnswer(event, personaId, tabId, questionId) {

    let dbField                   = "";
    let field: any;
    
    if(tabId + 1 == 1) {
      field                       = this.goalsQuestions.find( function (obj) { return obj.id == questionId + 1; });
    } else if(tabId + 1 == 2) {
      field                       = this.engagementQuestions.find( function (obj) { return obj.id == questionId + 1; });
    } else if(tabId + 1 == 3) {
      field                       = this.expectationQuestions.find( function (obj) { return obj.id == questionId + 1; });
    } else if(tabId + 1 == 4) {
      field                       = this.journeyQuestions.find( function (obj) { return obj.id == questionId + 1; });
    }

    if(field) {
      dbField                     = field.db;
    }

    const payload = {
      memberId : this.auth.getUserId(),
      personaId: personaId,
      tab: tabId + 1,
      dbField: dbField,
      value: event.target.value
    }

    console.log(payload);

    this.api.post('v2/personification/clients/' + this.clientId + '/prework', payload)
      .subscribe(data => {
        data.status != "success" && this.ui.showSnackbar("Sorry a server has occured. If this keeps happening please contact support.", "failure");
      }, err => {
        this.ui.showSnackbar("Oops, something went wrong. Please check your connection and make sure your responses have been saved.", "failure");
      });

  }

  trackByIndex(index,value){
    return index;
  }

}
