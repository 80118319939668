<div class="cs-tabs submissions-tab circle-initials segmentations-submissions new-submissions-design" *ngIf="!isLoading">

  <div class="full-frame">

    <div class="submissions-title">Responses</div>

    <div class="segmentation-table">
      <div class="segmentation-table-title">Categories & Segments</div>
      <div class="category-wrapper" *ngFor="let data of responseData">
        <div class="initials" [matTooltip]="showMemberHover(data.member_id)">{{ addInitials(data.member_id) }}</div>
        <div class="category-title">
          {{data.category}}
        </div>
        <div class="segment-wrapper">
           <div class="segment" *ngFor="let segment of data.segments">{{ segment.segment }}</div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>
