import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';

import { forkJoin } from 'rxjs';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-problem-priority',
  templateUrl: './problem-priority.component.html',
  styleUrls: ['./problem-priority.component.scss']
})
export class ProblemPriorityComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;
  isSubmitting                = false;

  clientSlug: string;
  clientId: number;
  meetingId: number;
  preworkVideo: string;

  questions                   = [
    { id: 1, question: 'What is the need that your prospect is trying to meet?', subHeading: 'Explain the goal that your prospect is trying to accomplish.', placeholder: 'E.g. increase church attendance' },
    { id: 2, question: 'What action should the prospect take to meet his need?', subHeading: 'Talk about the activity(ies) that your prospect would ideally run to meet his need.', placeholder: 'E.g. give practical training to leaders' },
    { id: 3, question: 'What is the problem that is standing in the way?', subHeading: 'Talk about the hurdle that your prospect needs to overcome.', placeholder: 'E.g. poor communication between leadership and team ' },
    { id: 4, question: 'What is your brand\'s solution for this problem?', subHeading: 'Describe how your brand will specifically address this particular issue.', placeholder: 'E.g. practical training for church leaders and teams' },
  ];
  problemPriorityData         = [
    {id: 1, questions: this.questions.map(question => ({...question, answer: '' })) }
  ];

  @ViewChild('MatTabGroup', { static: false }) tabGroup : MatTabGroup;
  selectedIndex               = 0;
  maxIndex                    = this.problemPriorityData.length - 1;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private titleService: Title ) {
      this.titleService.setTitle("Problem Priority | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    let meetingapi              = this.api.get('v1/meetings/slug/' + this.clientSlug);
    let accountapi              = this.api.get('v2/accounts/clients/' + this.auth.getMeetingId() + '/settings');

    forkJoin([ meetingapi, accountapi ])
      .subscribe(results => {
        console.log(results);
        if(results[0].status == "success" && results[1].status == "success") {

          this.clientId         = results[0].clientId;
          this.meetingId        = results[0].meetingId;

          let video             = results[1].data.find( function (obj) { return obj.setting == "problem_priority_prework_video";});
          if(video) {
            this.preworkVideo   = video.video;
          }

          this.api.get('v1/meetings/' + this.meetingId + '/problemPriorityResponse/byUser/' + this.auth.getUserId())
          .subscribe(data => {
            console.log(data);
            if(data.status == "success") {
              let responses         = data.data.sort((a, b) => a.id - b.id);
              let totalResponses    = responses.length;
              responses.forEach((response, index) => {
                this.problemPriorityData[index].questions[0].answer = response.need;
                this.problemPriorityData[index].questions[1].answer = response.action;
                this.problemPriorityData[index].questions[2].answer = response.problem;
                this.problemPriorityData[index].questions[3].answer = response.solution;
                if(totalResponses - 1 > index) {
                  this.addAnother();
                }
              });
              this.isLoading        = false;
            } else {
              this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
            }
          }, err => {
            this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  submitData() {

    if(!this.isSubmitting) {

      this.isSubmitting           = true;

      const payload = {
        memberId: this.auth.getUserId(),
        problems: this.problemPriorityData.map(data => ({ ...data, questions: data.questions.filter(question => question.answer).length > 0 ? data.questions : [] }) ).filter(data => data.questions.length > 0)
      };

      //this.isLoading              = true;

      console.log(payload);

      this.api.post('v1/meetings/' + this.meetingId + '/problemPriority/matrix/addMultiple', payload)
        .subscribe(data => {
          if(data.status == "success") {
            // this.isLoading        = false;
            this.showThankYou     = true;
            this.isSubmitting     = false;
          } else {
            this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
          }
        }, err => {
          this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
        });

    }

  }

  addAnother() {
    this.problemPriorityData.push(
      {id: this.problemPriorityData.length + 1, questions: this.questions.map(question => ({...question, answer: '' })) },
    );
    this.maxIndex             = this.problemPriorityData.length - 1;
    this.changeTab(this.maxIndex);
  }

  showForm() {
    // reset
    // this.problemPriorityData    = this.problemPriorityData.slice(0,2).map(data => ({ ...data, questions: data.questions.map(question => ({...question, answer:'' })) }));
    // this.selectedIndex          = 0;
    // this.maxIndex               = this.problemPriorityData.length - 1;
    // hide thank you page
    this.showThankYou           = false;
  }

  onTabChanged(event) {
    this.selectedIndex          = event.index;
  }

  changeTab(index) {
    this.selectedIndex          = index;
  }

  checkDisabled() {
    if(this.isSubmitting) {
      return "csbutton next-button inactive";
    }
    return "csbutton next-button";
  }

}
