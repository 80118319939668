import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-imagination-destination',
  templateUrl: './imagination-destination.component.html',
  styleUrls: ['./imagination-destination.component.scss']
})
export class ImaginationDestinationComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;

  clientSlug: string;
  clientId: number;
  meetingId: number;

  questions                   = [
    { id: 1, field: 'question1', question: 'Where is your organization headquartered?', placeholder: 'Type your response here.', answer: '' },
    { id: 2, field: 'question2', question: 'Does your organization have offices apart from your headquarters? If yes, approximately how many and where are they located (especially if global)?', placeholder: 'Type your response here.', answer: '' },
    { id: 3, field: 'question3', question: 'How many people are employed in your organization?', placeholder: 'Type your response here.', answer: '' },
    { id: 4, field: 'question4', question: 'What does your leadership team look like?', placeholder: 'Type your response here.', answer: '' },
    { id: 5, field: 'question5', question: 'How is your board different in 2025 from how it is today?', placeholder: 'Type your response here.', answer: '' },
    { id: 6, field: 'question6', question: 'How many past customers do you have?', placeholder: 'Type your response here.', answer: '' },
    { id: 7, field: 'question7', question: 'How many current customers do you have?', placeholder: 'Type your response here.', answer: '' },
    { id: 8, field: 'question8', question: 'What are your different revenue streams?', placeholder: 'Type your response here.', answer: '' },
    { id: 9, field: 'question9', question: 'What is your annual revenue from customers (if applicable)?', placeholder: 'Type your response here.', answer: '' },
    { id: 10, field: 'question10', question: 'What is your annual revenue from donations (if applicable)?', placeholder: 'Type your response here.', answer: '' },
    { id: 11, field: 'question11', question: 'What percentage of your annual revenue are you spending on sales and marketing efforts?', placeholder: 'Type your response here.', answer: '' },
    { id: 12, field: 'question12', question: 'What percentage of your team size is in your sales and marketing teams?', placeholder: 'Type your response here.', answer: '' },
    { id: 13, field: 'question13', question: 'What percentage of your annual revenue are you spending on technology?', placeholder: 'Type your response here.', answer: '' },
    { id: 14, field: 'question14', question: 'What percentage of your team size is in your technology team?', placeholder: 'Type your response here.', answer: '' },
    { id: 15, field: 'question15', question: 'What are some top features of your technology that spring to mind?', placeholder: 'Type your response here.', answer: '' },
    { id: 16, field: 'question16', question: 'What are some current features that you are really happy about?', placeholder: 'Type your response here.', answer: '' },
    { id: 17, field: 'question17', question: 'What are some features that you plan to add soon to your software?', placeholder: 'Type your response here.', answer: '' },
    { id: 18, field: 'question18', question: 'Which other brands are competing with you at this point?', placeholder: 'Type your response here.', answer: '' },
    { id: 19, field: 'question19', question: 'How different is your software/technology from theirs?', placeholder: 'Type your response here.', answer: '' },
    { id: 20, field: 'question20', question: 'What is the one thing you did right in 2021 that helped you get to where you are today in 2025?', placeholder: 'Type your response here.', answer: '' }
  ];

  selectedIndex                       = 0;
  currentIndex                        = 1;

  maxGroupSelectedIndex               = 0;
  maxSelectedIndex                    = 0;

  maxIndex                            = this.questions.length - 1;
  @ViewChild('stepper', { static: false }) stepper : MatStepper;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private titleService: Title ) {
      this.titleService.setTitle("Imagination Destination | CornerStone Prework");
    }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;

          this.api.get('v1/meetings/' + this.meetingId + '/imaginationDestination/byUser/' + this.auth.getUserId())
          .subscribe(data => {
            console.log(data);
            if(data.status == "success") {

              if(data.data[0]) {
                this.questions[0].answer      = data.data[0].question1;
                this.questions[1].answer      = data.data[0].question2;
                this.questions[2].answer      = data.data[0].question3;
                this.questions[3].answer      = data.data[0].question4;
                this.questions[4].answer      = data.data[0].question5;
                this.questions[5].answer      = data.data[0].question6;
                this.questions[6].answer      = data.data[0].question7;
                this.questions[7].answer      = data.data[0].question8;
                this.questions[8].answer      = data.data[0].question9;
                this.questions[9].answer      = data.data[0].question10;
                this.questions[10].answer     = data.data[0].question11;
                this.questions[11].answer     = data.data[0].question12;
                this.questions[12].answer     = data.data[0].question13;
                this.questions[13].answer     = data.data[0].question14;
                this.questions[14].answer     = data.data[0].question15;
                this.questions[15].answer     = data.data[0].question16;
                this.questions[16].answer     = data.data[0].question17;
                this.questions[17].answer     = data.data[0].question18;
                this.questions[18].answer     = data.data[0].question19;
                this.questions[19].answer     = data.data[0].question20;
              }

              this.isLoading        = false;
            } else {
              this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
            }
          }, err => {
            this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });
  }

  moveStepper(index: number, stepper: MatStepper) {
    if(stepper.selectedIndex < this.maxIndex && index > 0) {
      stepper.selectedIndex     = stepper.selectedIndex + 1;
      this.currentIndex         = stepper.selectedIndex + 1;
    }
  }

  moveStepperBack(currentIndex, stepper: MatStepper) {
    if(currentIndex > 1 && currentIndex <= 20) {
      stepper.selectedIndex       = stepper.selectedIndex - 1;
      this.currentIndex           = stepper.selectedIndex + 1;
    }
  }

  submitData() {

    const payload = {
      memberId: this.auth.getUserId(),
      destinations: this.questions
    };

    console.log(payload);

    this.api.post('v1/meetings/' + this.meetingId + '/imaginationDestination/addMultiple', payload)
      .subscribe(data => {
        if(data.status == "success") {
          // this.isLoading        = false;
          this.showThankYou     = true;
        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  checkLeftArrowActive(index) {
    if(index == 1) {
      return "imagination-arrows inactive-arrow";
    }
    return "imagination-arrows";
  }

  checkRightArrowActive(index) {
    if(index == 20) {
      return "imagination-arrows inactive-arrow";
    }
    return "imagination-arrows";
  }

  showForm() {
    // hide thank you page
    this.showThankYou           = false;
    this.currentIndex           = 1;
  }

  onTabChanged(event) {
    this.selectedIndex          = event.index;
  }

  changeTab(index) {
    this.selectedIndex          = index;
  }

}
