<div class="cs-tabs bold-version" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="main-frame">
        <div class="video-content">
          Create a mental picture of how your brand will be operating in 2025<br>
          to help define its long-term goals in measurable terms.
        </div>
        <div class="video-frame">
          <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
            <source
              src="https://cornerstone.40parables.com/wp-content/uploads/2021/07/Imagination-Destination-CornerStone-Prework.mp4"
              type="video/mp4">
          </video>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="main-frame">
        <div class="question-heading">
          Please imagine that you and your brand are in 2025 when you answer these questions, and consider responding
          with as much detail as possible.
        </div>
        <div class="question-content" *ngIf="!showThankYou">
          <div class="imagination-destination-wrapper">

            <div class="imagination-numbers">
              {{ currentIndex }} / {{ maxIndex + 1 }}
            </div>

            <div class="arrows-wrapper" style="margin-top: -32px;">
              <div (click)="moveStepperBack(currentIndex, stepper)" [ngClass]="checkLeftArrowActive(currentIndex)"><span
                  class="material-icons">chevron_left</span></div>
              <div (click)="moveStepper(selectedIndex + 1, stepper)" [ngClass]="checkRightArrowActive(currentIndex)"
                style="margin-left: 2px;"><span class="material-icons">chevron_right</span></div>
            </div>

            <mat-horizontal-stepper #stepper linear>

              <mat-step *ngFor="let question of questions">
                <div class="question-block">
                  <div class="question-block-title" style="height: 80px;">{{ question.question }}</div>
                  <mat-form-field appearance="outline">
                    <textarea matInput [placeholder]="question.placeholder" [(ngModel)]="question.answer"></textarea>
                  </mat-form-field>
                </div>
              </mat-step>

            </mat-horizontal-stepper>


            <div class="actionBtn">
              <button mat-button class="csbutton next-button" (click)="saveBrandValue(currentIndex)">
                <ng-container *ngIf="currentIndex < maxIndex + 1">Next</ng-container>
                <ng-container *ngIf="currentIndex == maxIndex + 1">Share your responses</ng-container>
              </button>
            </div>
          </div>
        </div>
        <div class="thank-you" *ngIf="showThankYou">
          <span class="material-icons">check_circle</span>
          <div class="heading">Your responses have been accepted.</div>
          <div class="content">
            Got some more thoughts you'd like to add?<br />
            <a (click)="showForm()">Add more</a> to your response.
          </div>
        </div>

    <!-- </mat-tab> -->
  <!-- </mat-tab-group> -->
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>