<div class="cs-tabs bold-version" *ngIf="!isLoading">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">info</span>
        Instructions
      </ng-template>
      <div class="video-content">
        Using the right keywords or phrases will help you <br/>
        convey your brand messaging effectively.
      </div>
      <div class="video-frame">
        <!-- <iframe width="677" src="https://cornerstone.40parables.com/wp-content/uploads/2021/04/Golden-Pyramid.mp4" frameborder="0" allowfullscreen></iframe> -->
        <video controls="true" autoplay="autoplay" [muted]="'muted'" playsinline width="100%">
          <source src="https://cornerstone.40parables.com/wp-content/uploads/2021/05/Brand-Keywords.mp4"
            type="video/mp4">
        </video>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-icons" style="margin-right: 10px;">create</span>
        Exercise
      </ng-template>
      <div class="question-content" *ngIf="!showThankYou">
        <!-- dropdown -->
        <div class="dropdown">
          <div class="dropdown-title">
            Write down keywords or phrases that your brand should stop, continue, or start saying.
          </div>
          <div class="dropdown-wrapper" *ngFor="let keyword of keywords">
            <!-- <div class="dropdown-wrapper-left">{{ keyword.placeholder }}</div> -->
            <div class="dropdown-wrapper-left">
              <input [placeholder]="keyword.placeholder" [(ngModel)]="keyword.value" />
            </div>
            <div class="dropdown-wrapper-right" >
              <mat-select placeholder="Select" [(ngModel)]="keyword.action_selected" [ngClass]="updateSelect(keyword.action_selected)">
                <mat-option value="stop">Stop</mat-option>
                <mat-option value="continue">Continue</mat-option>
                <mat-option value="start">Start</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="add-another">
            <button mat-button class="csbutton greybutton" (click)="addKeyword()" ><span class="material-icons">add</span>ADD ANOTHER RESPONSE</button>
          </div>
          <div class="actionBtn">
            <button mat-button [disabled]="isSubmitting" [ngClass]="checkDisabled()" style="width: 300px;" (click)="submit()">SHARE YOUR RESPONSES</button>
          </div>
        </div>
      </div>
      <div class="thank-you" *ngIf="showThankYou">
        <div class="heading"><span class="material-icons">check_circle</span>Your response have been accepted.</div>
        <div class="content">
          Got some more thoughts you'd like to add?<br/>
          <a (click)="showForm()">Click here</a> to refresh and add more responses.
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>

