import { Component, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-brand-values-submissions',
  templateUrl: './brand-values-submissions.component.html',
  styleUrls: ['./brand-values-submissions.component.scss']
})
export class BrandValuesSubmissionsComponent implements OnInit {

  isLoading                     = true;

  members                       = [];
  submissions                   = [];
  clientSlug: any;
  clientId: number;
  meetingId: number;

  questions                   = [
    { id: 1, question: 'What are some core values that guide your personal lives?' },
    { id: 2, question: 'What are the most important values a brand must preserve?' },
    { id: 3, question: 'What should your brand consider sacred?' },
    { id: 4, question: 'What will you define as your brand\'s top 3 rules?' },
    { id: 5, question: 'What is it about the way you do things as a brand that sets you apart?' },
    { id: 6, question: 'What is it about your brand that matters most to the employee in you?' },
    { id: 7, question: 'What about your brand might matter most to a client?' },
    { id: 8, question: 'What does good work look like at your brand?' },
    { id: 9, question: 'What will your brand refuse to compromise on, even on the last day of its existence?' },
    { id: 10, question: 'At the end of the day, what one thing will your brand be remembered for?' },
  ];

  selectedIndex                       = 0;
  currentIndex                        = 1;
  maxIndex                            = this.questions.length;
  maxSelectedIndex                    = 0;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Brand Values Submissions | CornerStone Prework");
  }

  ngOnInit(): void {

    this.isLoading              = false;
    
    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.members          = data.data;

          this.api.get('v2/brand-values/clients/' + this.meetingId + '/prework/submissions')
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
                this.submissions  = data.data;
                this.isLoading    = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });

  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : '';
    }
    return initials;
  }

  responsesByMemberId(memberId, question) {
    let submissions         =  this.submissions.filter( function (obj) { return obj.member_id == memberId && obj.question_id == question; });
    if(submissions.length > 0) {
      return true;
    }
    return false;
  }

  valuesByMemberId(memberId, question) {
    let submissions         =  this.submissions.filter( function (obj) { return obj.member_id == memberId && obj.question_id == question; });
    if(submissions) {
      return submissions;
    }
    return false;
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

  moveStepper(index: number, stepper: MatStepper) {
    if(index <= this.maxIndex && index > 0) {
      stepper.selectedIndex     = stepper.selectedIndex + 1;
      this.currentIndex         = stepper.selectedIndex + 1;
    }
  }

  moveStepperBack(currentIndex, stepper: MatStepper) {
    if(currentIndex <= this.maxIndex && currentIndex > 0) {
      stepper.selectedIndex       = stepper.selectedIndex - 1;
      this.currentIndex           = currentIndex - 1;
    }
  }

}
