<main>

  <div class="audience-login" *ngIf="!isLoading">

    <div class="main-frame">

      <!-- Header -->
      <!-- -------- -->
      <div class="loginheader">
        <img style="width: 90px;" src='./assets/img/logo.png'>
        <div class="corner-store-logo">
          <img src="../../assets/img/prework-logo.svg">
        </div>
      </div>

      <div [ngSwitch]="signin">

        <div *ngSwitchCase="'signin'">

          <form [formGroup]="signinForm">

            <mat-form-field appearance="outline" floatLabell="never" class="login-input">
              <input matInput formControlName="meetingCode" placeholder="Client Code">
            </mat-form-field>

            <div class="actionBtn">
              <button mat-button type="submit" class='csbutton yellowbtn login-btn' [disabled]="signinForm.invalid" (click)="signIn()">GET STARTED</button>
            </div>

          </form>

        </div>

        <div *ngSwitchCase="'login'">

          <form>
          <!-- <form [formGroup]="loginForm"> -->
            <!-- <mat-form-field appearance="outline" float="never" class="login-select">
              <mat-select formControlName="member" placeholder="Select your name">
                <mat-option *ngFor="let member of allUsers" [value]="member.id">{{ member.first_name }} {{ member.last_name }}</mat-option>
              </mat-select>
            </mat-form-field>  -->

            <mat-form-field  appearance="outline" float="never" class="login-select">
              <input
                  placeholder="Type in your name"
                  matInput
                  [matAutocomplete]="auto"
                  [(ngModel)]="memberSearch" (ngModelChange)="filteredOptions = filterMember(memberSearch)"
                  [ngModelOptions]="{standalone: true}"
                  >
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let member of filteredOptions" [value]="member">
                    {{ member.name }}
                </mat-option>
                <mat-option *ngIf="!hideAddNewOption" [value]="99999999" (onSelectionChange)="addMember()"><b>Add: {{ getNewMember }}</b></mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div class="actionBtn">
              <button mat-button type="submit" class='csbutton yellowbtn login-btn' [disabled]="!finalMember" (click)="submitLogin()">Get Started</button>
            </div>

            <!-- <div class="logo-bottom">
              <img src="../../assets/img/epicpay.png">
            </div> -->

          </form>

        </div>

        <div class="matspinner" *ngSwitchCase="'loading'">
          <mat-spinner strokeWidth="5"></mat-spinner>
        </div>

      </div>
    </div>

  </div>

</main>
