import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UiService } from '../shared/ui.service';
import { ApiService } from '../shared/api.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss']
})
export class KeywordsComponent implements OnInit {

  showThankYou                = false;
  isLoading                   = true;
  isSubmitting                = false;

  clientSlug: string;
  clientId: number;
  meetingId: number;

  keywords                    = [
    { value: '', placeholder: 'E.g. catalyze', action_selected: null,  },
    { value: '', placeholder: 'E.g. accelerate', action_selected: null },
    { value: '', placeholder: 'E.g. unreached', action_selected: null },
    { value: '', placeholder: 'E.g. unengaged', action_selected: null },
    { value: '', placeholder: 'E.g. collaborative', action_selected: null },
    { value: '', placeholder: 'E.g. catalytic leaders', action_selected: null },
    { value: '', placeholder: 'E.g. missional programs', action_selected: null },
    { value: '', placeholder: 'E.g. foundational donors', action_selected: null },
    { value: '', placeholder: 'E.g. business-as-mission', action_selected: null },
    { value: '', placeholder: 'E.g. collaborative ministry networks', action_selected: null },
  ];

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title,
    private auth: AuthService
     ) {
      this.titleService.setTitle("Brand Keywords | CornerStone Prework");
     }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.isLoading        = false;

          this.api.get('v2/brand-keywords/clients/' + this.clientId + '/byUser/' + this.auth.getUserId())
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
      
                this.keywords.forEach((keyword, i) => {
                  if(data.data[i]) {
                    keyword.value             = data.data[i].keyword;
                    keyword.action_selected   = data.data[i].action;
                  }
                });

                if(data.data.length > this.keywords.length) {
                  data.data.forEach((extra, i) => {
                    if(i >= this.keywords.length) {
                      this.keywords.push({
                        value: extra.keyword,
                        placeholder: 'Type your answer here.',
                        action_selected: extra.action
                      });
                    }
                  })
                }
                  
      
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });

  }

  updateSelect(action) {
    if(action == "start") {
      return "start-select";
    } else if(action == "continue") {
      return "continue-select";
    } else if(action == "stop") {
      return "stop-select";
    } else {
      return null;
    }
  }

  addKeyword() {
    this.keywords.push({ value: '', placeholder: 'Type your answer here.', action_selected: null },);
  }

  submit() {

    if(!this.isSubmitting) {

      this.isSubmitting           = true;

      const payload = {
        keywords: this.keywords.filter(keyword => keyword.value || keyword.action_selected),
        memberId : this.auth.getUserId(),
      };
      console.log(payload);

      let actionSelected = [...payload.keywords.filter(keyword => keyword.action_selected)];
      let valueSelected  = [...payload.keywords.filter(keyword => keyword.value)];

      if(payload.keywords.length == 0) {
        this.ui.showSnackbar("Please add some keywords.", "failure");
        this.isSubmitting         = false;
        return;
      }

      if(actionSelected.length !== payload.keywords.length || valueSelected.length !== payload.keywords.length) {
        this.ui.showSnackbar("Please select the choices in the drop-down button to submit.", "failure");
        this.isSubmitting         = false;
        return;
      }

      this.api.post('v2/brand-keywords/clients/' + this.clientId + '/prework', payload)
        .subscribe(data => {
          console.log(data);
          if(data.status == "success") {
            this.showThankYou     = true;
            this.isSubmitting     = false;
          } else {
            this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
          }
        }, err => {
            this.ui.showSnackbar("Network error, Please check the network and do it again.", "failure");
        });

    }

  }

  showForm() {
    // reset
    // this.keywords              = this.keywords.map(keyword => ({ ...keyword, value: null, action_selected: null }));

    // hide thank you page
    this.showThankYou           = false;
  }

  checkDisabled() {
    if(this.isSubmitting) {
      return "csbutton bluebutton next-button yellowbtn inactive";
    }
    return "csbutton bluebutton next-button yellowbtn";
  }

}
