import { OnInit, Component, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AsideService } from './shared/aside.service';
import { MatSidenav } from "@angular/material/sidenav";

import { filter } from 'rxjs/operators';

import { UiService } from './shared/ui.service';
import { ApiService } from './shared/api.service';
import { AuthService } from './shared/auth.service';
import { LightboxService } from './shared/lightbox.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  @ViewChild('asidenav') public asidenav: MatSidenav;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  clientSlug: string;
  clientId: number;
  meetingName: string;
  meetingLogo: string;
  meetingId: number;
  tools                       = [];
  currentUrl: any;

  screenHeight                = window.innerHeight;
  screenWidth                 = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight         = window.innerHeight;
    this.screenWidth          = window.innerWidth;
    if(this.screenWidth < 900) {
      this.menuopen           = false;
    } else {
      this.menuopen           = true;
    }
  }

  isMenuShowing               = false;
  @HostListener('document:click', ['$event'])
    documentClick(event: MouseEvent) {
      if(this.isMenuShowing && !(event.target as Element).className.includes('username-icon') ) {
        this.isMenuShowing      = false;
      }
    }

  // Change menuside to 'over' and menuopen to 'false'
  // if you wish to by default hide the menu on the left
  menuopen                    = true;
  // collapsed                   = "open-icon";

  activeMenu                  = "";
  pageHeading                 = "";

  // Hide menu button and header
  menubtn                     = false;
  // showheader                  = false;

  // admininitials               = ' ';
  // administrator               = ' ';
  // adminemail                  = ' ';

  // Public URLs like login
  publicUrl                   = ['/login','/','/start'];
  isPublicUrl                 = true;

  username: any;

  public constructor(
    private ui: UiService,
    private api: ApiService,
    private auth: AuthService,
    public aside: AsideService,
    private route: ActivatedRoute,
    private router: Router,
    public lightbox: LightboxService ) {
      this.onResize();
    }

  ngOnInit() {

    if(this.screenWidth < 900) {
      this.menuopen                   = false;
    } else {
      this.menuopen                   = true;
    }

    this.clientSlug                   = this.route.snapshot.paramMap.get('clientSlug');

    // If this is a public page remove the header
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      ).subscribe(data => {

        this.clientSlug               = data['url'].split("/")[1];
        this.currentUrl               = data['url'];
        console.log("here");
        console.log(this.currentUrl);
        console.log(this.clientSlug);

        if( this.publicUrl.includes( data['url'] )) {
          console.log("Are we here");
          this.menuopen       = false;
          this.isPublicUrl    = true;
        } else if( data['url'].includes('/start') ) {
          this.menuopen         = false;
          this.isPublicUrl      = true;
        } else {

          if(!this.auth.isLoggedIn()) {
            console.log(this.currentUrl);
            if(!this.currentUrl.includes('/login')) {
              this.router.navigateByUrl("login?url=" + this.currentUrl);
            }
            return false;
          }

          this.username                     = this.auth.getUserName();
          this.meetingName                  = this.auth.getUserMeetingTitle();
          this.meetingLogo                  = this.auth.getUserMeetingDarkLogo();

          this.isPublicUrl    = false;
          if(this.screenWidth > 899) {
            this.menuopen     = true;
            this.menubtn      = true;
          } else {
            this.menubtn        = false;
          }

          if(data['url'] == "/" + this.clientSlug + "/golden-pyramid" || data['url'] == "/" + this.clientSlug + "/golden-pyramid/submissions") {
            this.activeMenu       = "goldenpyramid";
            this.pageHeading      = "Golden Pyramid";
          } else if(data['url'] == "/" + this.clientSlug + "/problem-priority" || data['url'] == "/" + this.clientSlug + "/problem-priority/submissions") {
            this.activeMenu       = "problempriority";
            this.pageHeading      = "Problem Priority";
          } else if(data['url'] == "/" + this.clientSlug + "/segmentation" || data['url'] == "/" + this.clientSlug + "/segmentation/submissions") {
            this.activeMenu       = "segmentation";
            this.pageHeading      = "Segmentation";
          } else if(data['url'] == "/" + this.clientSlug + "/brand-advocacy" || data['url'] == "/" + this.clientSlug + "/brand-advocacy/submissions") {
            this.activeMenu       = "brandadvocacy";
            this.pageHeading      = "Brand Advocacy";
          } else if(data['url'] == "/" + this.clientSlug + "/brand-values" || data['url'] == "/" + this.clientSlug + "/brand-values/submissions") {
            this.activeMenu       = "brandvalues";
            this.pageHeading      = "Brand Values";
          } else if(data['url'] == "/" + this.clientSlug + "/keywords" || data['url'] == "/" + this.clientSlug + "/keywords/submissions") {
            this.activeMenu       = "keywords";
            this.pageHeading      = "Brand Keywords";
          } else if(data['url'] == "/" + this.clientSlug + "/brand-voice-chart" || data['url'] == "/" + this.clientSlug + "/brand-voice-chart/submissions") {
            this.activeMenu       = "brandvoicechart";
            this.pageHeading      = "Brand Voice Chart";
          } else if(data['url'] == "/" + this.clientSlug + "/brand-personality" || data['url'] == "/" + this.clientSlug + "/brand-personality/submissions") {
            this.activeMenu       = "brandpersonality";
            this.pageHeading      = "Brand Personality";
          } else if(data['url'] == "/" + this.clientSlug + "/brand-voice" || data['url'] == "/" + this.clientSlug + "/brand-voice/submissions") {
            this.activeMenu       = "brandvoice";
            this.pageHeading      = "Brand Voice";
          } else if(data['url'] == "/" + this.clientSlug + "/brand-messaging" || data['url'] == "/" + this.clientSlug + "/brand-messaging/submissions") {
            this.activeMenu       = "brandmessaging";
            this.pageHeading      = "Brand Messaging";
          } else if(data['url'] == "/" + this.clientSlug + "/imagination-destination" || data['url'] == "/" + this.clientSlug + "/imagination-destination/submissions") {
            this.activeMenu       = "imaginationdestination";
            this.pageHeading      = "Imagination Destination";
          } else if(data['url'] == "/" + this.clientSlug + "/personification" || data['url'] == "/" + this.clientSlug + "/personification/submissions") {
            this.activeMenu       = "personification";
            this.pageHeading      = "Personification";
          }

          this.api.get('v1/meetings/slug/' + this.clientSlug)
            .subscribe(data => {
              if(data.status == "success") {
                this.clientId         = data.clientId;
                this.meetingId        = data.meetingId;
                this.tools            = data.tools;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        }
      });

  }

  ngOnViewInit() {
    // Setup the aside drawer
    this.aside.setSidenav(this.asidenav);
  }

  ngAfterViewInit(): void {
    if(this.screenWidth > 900 && !this.isPublicUrl) {
      setTimeout(() => {
        this.sidenav.open();
      }, 250);
    }
    console.log(this.menubtn);
    console.log(this.screenWidth);
    console.log(this.menuopen);
    console.log(this.isPublicUrl);
  }

  /*closeMenu() {
    if(this.screenWidth < 900) {
      this.sidenav.toggle();
    }
  }

  openMenu() {
    this.sidenav.toggle();
  }*/

  closeMenuMobile() {
    if(this.screenWidth < 900) {
      this.sidenav.toggle();
    }
  }

  closeMenu() {
    if(this.screenWidth > 899) {
      this.menubtn            = false;
      this.menuopen           = true;
      // this.collapsed          = "collapsed-icon";
    }
  }

  openMenu() {
    if(this.screenWidth > 899) {
      this.menubtn            = true;
      // this.collapsed          = "open-icon";
    }
  }

  openMobileMenu() {
    this.menubtn              = true;
  }

  closeMobileMenu() {
    this.menubtn              = false;
  }

  checkActiveMenu(menu) {
    if(menu == 'goldenpyramid' && this.activeMenu == 'goldenpyramid') {
      return "active";
    } else if(menu == 'problempriority' && this.activeMenu == 'problempriority') {
      return "active";
    } else if(menu == 'segmentation' && this.activeMenu == 'segmentation') {
      return "active";
    } else if(menu == 'brandadvocacy' && this.activeMenu == 'brandadvocacy') {
      return "active";
    } else if(menu == 'brandvalues' && this.activeMenu == 'brandvalues') {
      return "active";
    } else if(menu == 'keywords' && this.activeMenu == 'keywords') {
      return "active";
    } else if(menu == 'brandvoicechart' && this.activeMenu == 'brandvoicechart') {
      return "active";
    } else if(menu == 'brandpersonality' && this.activeMenu == 'brandpersonality') {
      return "active";
    } else if(menu == 'brandvoice' && this.activeMenu == 'brandvoice') {
      return "active";
    } else if(menu == 'brandmessaging' && this.activeMenu == 'brandmessaging') {
      return "active";
    } else if(menu == 'buyerjourney' && this.activeMenu == 'buyerjourney') {
      return "active";
    } else if(menu == 'personification' && this.activeMenu == 'personification') {
      return "active";
    } else if(menu == 'imaginationdestination' && this.activeMenu == 'imaginationdestination') {
      return "active";
    }
    return "";
  }

  showPrework(exercise) {

    if(this.tools) {
      let tool            = this.tools.find( function(obj) { return obj.title == exercise; });
      if(tool && tool.prework_state == "on") {
        return true;
      }
    }
    return false;

  }

  goToHome() {
    if(this.currentUrl && this.currentUrl.includes("submissions")) {

    } else {
      this.router.navigate(['/' + this.clientSlug + '/start']);
    }
  }

  isHomeLink() {
    if(this.currentUrl && this.currentUrl.includes("submissions")) {
      return "default";
    }
    return ""
  }

  getUrl(exercise) {
    let submissions       = "";
    if(this.currentUrl && this.currentUrl.includes("submissions")) {
      submissions         = "/submissions";
    }

    if(exercise == "Golden Pyramid") {
      if(this.clientSlug == "cmc") {
        window.location.href    = "https://cornerstone.40parables.com/prework/" + this.clientSlug + "/golden-pyramid" + submissions;
      } else {
        this.router.navigate(["/" + this.clientSlug + "/golden-pyramid" + submissions]);
      }
    } else if(exercise == "Problem Priority") {
      if(this.clientSlug == "cmc") {
        window.location.href    = "https://cornerstone.40parables.com/prework/" + this.clientSlug + "/problem-priority" + submissions;
      } else {
        this.router.navigate(["/" + this.clientSlug + "/problem-priority" + submissions]);
      }
    } else if(exercise == "Segmentation") {
      if(this.clientSlug == "cmc") {
        window.location.href    = "https://cornerstone.40parables.com/prework/" + this.clientSlug + "/segmentation" + submissions;
      } else {
        this.router.navigate(["/" + this.clientSlug + "/segmentation" + submissions]);
      }
    } else if(exercise == "Brand Advocacy") {
      this.router.navigate(["/" + this.clientSlug + "/brand-advocacy" + submissions]);
    } else if(exercise == "Brand Personality") {
      this.router.navigate(["/" + this.clientSlug + "/brand-personality" + submissions]);
    } else if(exercise == "Personification") {
      this.router.navigate(["/" + this.clientSlug + "/personification" + submissions]);
    } else if(exercise == "Buyer Journey") {
      window.location.href    = "https://cornerstone.40parables.com/prework/" + this.clientSlug + "/buyer-journey" + submissions;
    } else if(exercise == "Brand Voice") {
      this.router.navigate(["/" + this.clientSlug + "/brand-voice" + submissions]);
    } else if(exercise == "Brand Values") {
      this.router.navigate(["/" + this.clientSlug + "/brand-values" + submissions]);
    } else if(exercise == "Brand Messaging") {
      this.router.navigate(["/" + this.clientSlug + "/brand-messaging" + submissions]);
    } else if(exercise == "Brand Keywords") {
      this.router.navigate(["/" + this.clientSlug + "/keywords" + submissions]);
    } else if(exercise == "Brand Voice Chart") {
      this.router.navigate(["/" + this.clientSlug + "/brand-voice-chart" + submissions]);
    } else if(exercise == "Imagination Destination") {
      this.router.navigate(["/" + this.clientSlug + "/imagination-destination" + submissions]);
    }

    if(!this.menubtn) {
      this.sidenav.toggle();
    }

    console.log(this.menubtn);
    console.log(this.screenWidth);
    console.log(this.menuopen);
    console.log(this.isPublicUrl);

  }

  logOut(){
    this.auth.logout();
  }

  toggleMenu() {
    this.isMenuShowing  = !this.isMenuShowing;
  }

  get isSubmissionPage() {
    if(this.currentUrl && this.currentUrl.includes("submissions")) {
      return "- Submissions";
    }
    return "";
  }

  showMenu() {
    if(this.menubtn && this.screenWidth > 899) {
      return true;
    } else if(this.screenWidth < 900) {
      return true;
    }
    return false;
  }


}
