<div class="cs-tabs submissions-tab keywords-submissions new-submissions-design" *ngIf="!isLoading">

  <div class="full-frame">

    <div class="submissions-title">Responses</div>

    <div class="question-content">
      <div class="keywords-wrapper">
        <mat-horizontal-stepper disableRipple="true" #stepper>

          <ng-template matStepperIcon="edit" let-index="index">
            <mat-icon *ngIf="index == 0" style="color: #fcabab;font-size: 32px; ">cancel</mat-icon>
            <mat-icon *ngIf="index == 1" style="color: #ffd89f;font-size: 32px;">outbound</mat-icon>
            <mat-icon *ngIf="index == 2" style="color: #c7f3b9;font-size: 32px;">check_circle</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="done" let-index="index">
            <mat-icon *ngIf="index == 0" style="color: #fcabab;font-size: 32px;">cancel</mat-icon>
            <mat-icon *ngIf="index == 1" style="color: #ffd89f;font-size: 32px;">outbound</mat-icon>
            <mat-icon *ngIf="index == 2" style="color: #c7f3b9;font-size: 32px;">check_circle</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="step0">
            <mat-icon style="color: #fcabab;">cancel</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="step1">
            <mat-icon style="color: #ffd89f;">outbound</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="step2">
            <mat-icon style="color: #c7f3b9;">check_circle</mat-icon>
          </ng-template>

          

          <mat-step *ngFor="let data of keywordsData; let i = index;" state="{{ 'step' + i}}">
            <ng-template matStepLabel>{{ data.tab }}</ng-template>
            <div class="question-block">
              <div class="responses-wrapper">
                <ng-container *ngFor="let member of members">
                  <div class="response" *ngIf="responsesByMemberId(member.id, data.tab).length > 0">
                    <div class="member" [matTooltip]="showMemberHover(member.id)">{{ addInitials(member.id) }}</div>
                    <div class="response-result">
                      <div class="result" *ngFor="let result of responsesByMemberId(member.id, data.tab)">{{ result.keyword }}</div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>

  </div>

</div>
