import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-keywords-submissions',
  templateUrl: './keywords-submissions.component.html',
  styleUrls: ['./keywords-submissions.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class KeywordsSubmissionsComponent implements OnInit {

  clientSlug: string;
  clientId: number;
  meetingId: number;
  members                       = [];

  responseData                  = [];
  keywordsData                  = [
    { id: 1, tab: 'STOP' },
    { id: 2, tab: 'CONTINUE' },
    { id: 3, tab: 'START' }
  ];
  isLoading                     = true;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Brand Keywords Submissions | CornerStone Prework");
  }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId         = data.clientId;
          this.meetingId        = data.meetingId;
          this.members          = data.data;

          this.api.get('v1/meetings/' + this.meetingId + '/voicetone/keywords/votes')
          .subscribe(data => {
            console.log(data);
            if(data.status == "success") {
              this.responseData         = data.data;
              this.isLoading            = false;
            } else {
              this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
            }
          }, err => {
            this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
          });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });

  }

  responsesByMemberId(memberId, tab) {
    return this.responseData.filter( function (obj) { return obj.memberId == memberId && obj.initialAction == tab.toLowerCase(); });
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      if(member.lastName) {
        return member.firstName + " " + member.lastName;
      } else {
        return member.firstName;
      }
    }
  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : '';
    }
    return initials;
  }

}
