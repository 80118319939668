import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-brand-advocacy-submissions',
  templateUrl: './brand-advocacy-submissions.component.html',
  styleUrls: ['./brand-advocacy-submissions.component.scss']
})
export class BrandAdvocacySubmissionsComponent implements OnInit {

  isLoading                     = true;

  members                       = [];
  personas                      = [];
  responseData                  = [];
  clientSlug: any;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Brand Advocacy Submissions | CornerStone Prework");
  }

  ngOnInit(): void {

    this.clientSlug             = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/clients/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.members          = data.data;

          let response          = this.api.get('v1/preworks/responses/client/' + data.clientId);
          let personas          = this.api.get('v1/preworks/client/' + data.clientId + '/brandadvocacy' );

          forkJoin([ response, personas ])
            .subscribe(results => {
              console.log(results);
              if(results[0].status == "success" && results[1].status == "success") {
                this.responseData         = results[0].data;
                this.personas             = results[1].data;
                this.isLoading            = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
      });
  }

  addInitials(id) {
    let member              = this.responseData.find(function (obj) { return obj.member_id == id; });
    let initials            = "";
    if(member) {
      initials               += member.member_first_name.toUpperCase().substring(0, 1);
      initials               += member.member_last_name ? member.member_last_name.toUpperCase().substring(0, 1) : null;
    }
    return initials;
  }

  // addInitialsPersona(id) {
  //   let persona              = this.personas.find(function (obj) { return obj.id == id; });
  //   let finalInitials        = "";
  //   if(persona) {
  //     let initials           = persona.persona.split(" ");
  //     for(let initial of initials) {
  //       finalInitials       += initial.substring(0, 1).toUpperCase();
  //     }
  //   }
  //   return finalInitials;
  // }

  responsesByMemberId(memberId) {
    return this.responseData.filter( function (obj) { return obj.member_id == memberId; });
  }

  responsesByPersonaId(personaId) {
    return this.responseData.filter( function (obj) { return obj.persona_id == personaId; });
  }

  showMemberHover(id) {
    let member              = this.responseData.find(function (obj) { return obj.member_id == id; });
    if(member) {
      if(member.member_last_name) {
        return member.member_first_name + " " + member.member_last_name;
      } else {
        return member.member_first_name;
      }
    }
  }

}
