<div class="cs-tabs submissions-tab brand-personality-submissions new-submissions-design" *ngIf="!isLoading">

  <div class="full-frame">

    <div class="submissions-title">Responses</div>

    <div class="question-content">
      <div class="brand-personality-submissions-wrapper">
        <div class="brand-personality-numbers">
          {{ currentIndex }} / {{ maxIndex }}
        </div>

        <div class="actionBtn adjust-margin">
          <div class="brand-personality-arrows" [class.disabled]="currentIndex === 1" (click)="moveStepperBack(currentIndex, stepper)" ><span class="material-icons">chevron_left</span></div>
          <div class="brand-personality-arrows" [class.disabled]="currentIndex === maxIndex" (click)="moveStepper(selectedIndex + 1, stepper)"><span class="material-icons">chevron_right</span></div>
        </div>

        <mat-horizontal-stepper #stepper linear>

          <mat-step *ngFor="let question of questions">
            <div class="question-block">
                <div class="question-block-title">{{ question.question }}</div>
                <div class="responses-wrapper">
                  <ng-container *ngFor="let member of members">
                    <div class="response" *ngIf="responsesByMemberId(member.id, question.answers)">
                      <div class="member" [matTooltip]="showMemberHover(member.id)">{{ addInitials(member.id) }}</div>
                      <div class="response-result">
                        <div class="result" *ngFor="let result of traitsByMemberId(member.id, question.answers)">{{ result.trait }}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
            </div>
          </mat-step>

        </mat-horizontal-stepper>

      </div>
    </div>

  </div>

</div>

<div class="matspinner" *ngIf="isLoading">
  <mat-spinner strokeWidth="5"></mat-spinner>
</div>

