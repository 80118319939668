import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material
import { AppMaterialModule } from './app-material.module';

// Pipes
import { FilterPipe } from './pipe/filter.pipe';

// Services
import { AsideService } from './shared/aside.service';
import { ApiService } from './shared/api.service';
import { AuthService } from './shared/auth.service';
import { UiService } from './shared/ui.service';

// Routes
import { routes } from './app.routes';

// App Components
import { AppComponent } from './app.component';
import { AsideComponent } from './aside/aside.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrandAdvocacyComponent } from './brand-advocacy/brand-advocacy.component';
import { BrandValuesComponent } from './brand-values/brand-values.component';
import { KeywordsComponent } from './keywords/keywords.component';
import { BrandVoiceChartComponent } from './brand-voice-chart/brand-voice-chart.component';
import { BrandPersonalityComponent } from './brand-personality/brand-personality.component';
import { VoiceAndToneComponent } from './voice-and-tone/voice-and-tone.component';
import { BrandPersonalitySubmissionsComponent } from './brand-personality/brand-personality-submissions/brand-personality-submissions.component';
import { VoiceAndToneSubmissionsComponent } from './voice-and-tone/voice-and-tone-submissions/voice-and-tone-submissions.component';
import { BrandValuesSubmissionsComponent } from './brand-values/brand-values-submissions/brand-values-submissions.component';
import { BrandAdvocacySubmissionsComponent } from './brand-advocacy/brand-advocacy-submissions/brand-advocacy-submissions.component';
import { KeywordsSubmissionsComponent } from './keywords/keywords-submissions/keywords-submissions.component';
import { GoldenPyramidComponent } from './golden-pyramid/golden-pyramid.component';
import { ProblemPriorityComponent } from './problem-priority/problem-priority.component';
import { SegmentationComponent } from './segmentation/segmentation.component';
import { StartComponent } from './start/start.component';
import { ProblemPrioritySubmissionsComponent } from './problem-priority/problem-priority-submissions/problem-priority-submissions.component';
import { SegmentationSubmissionsComponent } from './segmentation/segmentation-submissions/segmentation-submissions.component';
import { GoldenPyramidSubmissionsComponent } from './golden-pyramid/golden-pyramid-submissions/golden-pyramid-submissions.component';
import { BrandVoiceChartSubmissionsComponent } from './brand-voice-chart/brand-voice-chart-submissions/brand-voice-chart-submissions.component';
import { ImaginationDestinationComponent } from './imagination-destination/imagination-destination.component';
import { ImaginationDestinationSubmissionsComponent } from './imagination-destination/imagination-destination-submissions/imagination-destination-submissions.component';
import { BrandMessagingComponent } from './brand-messaging/brand-messaging.component';
import { BrandMessagingSubmissionsComponent } from './brand-messaging/brand-messaging-submissions/brand-messaging-submissions.component';
import { PersonificationComponent } from './personification/personification.component';
import { PersonificationSubmissionsComponent } from './personification/personification-submissions/personification-submissions.component';
import { BrandVoiceComponent } from './brand-voice/brand-voice.component';
import { BrandVoiceSubmissionsComponent } from './brand-voice/brand-voice-submissions/brand-voice-submissions.component';


@NgModule({
  declarations: [
    AppComponent,
    AsideComponent,
    LightboxComponent,
    FilterPipe,
    DashboardComponent,
    LoginComponent,
    PageNotFoundComponent,
    BrandAdvocacyComponent,
    BrandValuesComponent,
    KeywordsComponent,
    BrandVoiceChartComponent,
    BrandPersonalityComponent,
    VoiceAndToneComponent,
    BrandPersonalitySubmissionsComponent,
    VoiceAndToneSubmissionsComponent,
    BrandValuesSubmissionsComponent,
    BrandAdvocacySubmissionsComponent,
    KeywordsSubmissionsComponent,
    GoldenPyramidComponent,
    ProblemPriorityComponent,
    SegmentationComponent,
    StartComponent,
    ProblemPrioritySubmissionsComponent,
    SegmentationSubmissionsComponent,
    GoldenPyramidSubmissionsComponent,
    BrandVoiceChartSubmissionsComponent,
    ImaginationDestinationComponent,
    ImaginationDestinationSubmissionsComponent,
    BrandMessagingComponent,
    BrandMessagingSubmissionsComponent,
    PersonificationComponent,
    PersonificationSubmissionsComponent,
    BrandVoiceComponent,
    BrandVoiceSubmissionsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    routes
  ],
  providers: [AsideService, ApiService, AuthService, UiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
