import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { UiService } from 'src/app/shared/ui.service';

@Component({
  selector: 'app-brand-voice-chart-submissions',
  templateUrl: './brand-voice-chart-submissions.component.html',
  styleUrls: ['./brand-voice-chart-submissions.component.scss']
})
export class BrandVoiceChartSubmissionsComponent implements OnInit {

  isLoading                     = true;

  members                       = [];
  responseData                  = [];
  /*responseData                  = [
    { id: 1, tab: 'Voice word 1', data: [
      { id: 1, member_id: '383', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
      { id: 2, member_id: '384', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
      { id: 3, member_id: '385', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
    ] },
    { id: 1, tab: 'Voice word 2', data: [
      { id: 1, member_id: '383', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
      { id: 2, member_id: '384', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
      { id: 3, member_id: '385', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
    ] },
    { id: 1, tab: 'Voice word 3', data: [
      { id: 1, member_id: '383', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
      { id: 2, member_id: '384', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
      { id: 3, member_id: '385', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
    ] },
    { id: 1, tab: 'Voice word 4', data: [
      { id: 1, member_id: '383', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
      { id: 2, member_id: '384', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
      { id: 3, member_id: '385', synonyms: 'Cupcake', description: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', use: 'lemon drops dragée cookie gummies. Wafer cake tart. Bonbon bear claw sugar.', noUse: 'bear claw sugar.' },
    ] },
  ];*/
  clientSlug: any;
  clientId: number;
  meetingId: number;

  constructor(
    private ui: UiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private titleService:Title
  ) {
    this.titleService.setTitle("Brand Voice Chart Submissions | CornerStone Prework");
  }

  ngOnInit(): void {

    this.clientSlug                       = this.route.snapshot.paramMap.get('clientSlug');

    this.api.get('v1/meetings/slug/' + this.clientSlug)
      .subscribe(data => {
        console.log(data);
        if(data.status == "success") {

          this.clientId                   = data.clientId;
          this.meetingId                  = data.meetingId;
          this.members                    = data.data;

          this.api.get('v1/meetings/' + this.meetingId + '/voicetone/adjectives/charts')
            .subscribe(data => {
              console.log(data);
              if(data.status == "success") {
                this.responseData         = data.data;
                this.isLoading            = false;
              } else {
                this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
              }
            }, err => {
              this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
            });

        } else {
          this.ui.showSnackbar("Oops, something went wrong. Please check the network and do it again.", "failure");
        }
      }, err => {
        this.ui.showSnackbar("Network error, please check the network and do it again.", "failure");
    });

  }

  addInitials(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    let initials            = "";
    if(member) {
      initials               += member.firstName.toUpperCase().substring(0, 1);
      initials               += member.lastName ? member.lastName.toUpperCase().substring(0, 1) : null;
    }
    return initials;
  }

  showMemberHover(id) {
    let member              = this.members.find(function (obj) { return obj.id == id; });
    if(member) {
      return member.firstName + " " + member.lastName;
    }
  }

  makeUppercase(string) {
    return string.toUpperCase();
  }

  // responsesByMemberId(memberId) {
  //   return this.responseData.filter( function (obj) { return obj.member_id == memberId; });
  // }


}
